<h5>Other Settings</h5>
<div [style.minHeight]="'40vh'">
  <!--  <span class="title"> Index Weights</span>-->

  <div class="weights-wrap">
    <div *ngIf="otherSettings?.sqs_Jobs">
      <h6>Candidate inflow settings</h6>
      <div fxLayout="row" fxLayoutGap="10px" *ngFor="let job of otherSettings.sqs_Jobs; let i = index"
        style="margin:15px 0 15px 30px;">
        <div fxFlex="90%">
          <h5 *ngIf="job.isDeactivated === 1" class="job-inactive ">Inactive</h5>
          <h5 *ngIf="job.isDeactivated !== 1" class="job-active ">
            Active
            <span *ngIf="job?.settings?.status?.pauseJobs" class="job-paused"> -- PAUSED -- </span>
            <small> (Every {{job.humanizedInterval}})</small>
          </h5>

          <div (click)="showJSONContentModal(dialog, job)">
            Q Name: <code>{{job.qName}}</code>
          </div>
          <div>
            Provider: <code>{{job.jsProvider}}</code>
          </div>
          <div>
            Api Url: <code>{{job.apiUrl}}</code>
          </div>

          <div>
            <div>
              Last Run Time: {{job.lastRunTime | date: 'shortTime'}};{{job.lastRunTime |
              amTimeAgo}};({{job.lastRunTime}}) <br />
              Next Run At: {{job.nextJobAt | date: 'shortTime'}}; <br />
              Job Created: {{job.createdAt | amCalendar}}
            </div>
            <div>
              <span *ngIf="job.lastAlertedDay2" style="color: red;font-weight: 600;"> In Day 2 Alert since:
                {{job.lastAlertedDay2 | amTimeAgo}}
              </span><br />
              <span *ngIf="job.lastAlertedDay7" style="color: red;font-weight: 600;"> In Day 7 Alert since:
                {{job.lastAlertedDay7 | amTimeAgo}}
              </span>
            </div>
          </div>
          <div>
            Field Map - {{job.jsProvider}} &nbsp;&nbsp;
            <!-- <button *ngIf="job.jsProvider === 'workday'" mat-icon-button [matMenuTriggerFor]="menu"
              style="position: absolute; top: 0; right: 0; z-index: 1;">
              <mat-icon>more_vert</mat-icon>
            </button> -->

            <button mat-button mat-raised-button (click)="showJSONContentModal(dialog, job?.settings?.api?.fieldMap)">
              View API Mapping
            </button>
            &nbsp;&nbsp;
            <button mat-button mat-raised-button
              (click)="showJSONContentModal(dialog, job?.emailListSettings?.emailListJSON?.customFieldMapping)">
              View Custom Field Mapping
            </button>
            <br /><br />
            <table *ngIf="job.jsProvider === 'workday'" mat-table [dataSource]="job.dataSource"
              class="mat-elevation-z8">

              <ng-container matColumnDef="clientFieldName">
                <th mat-header-cell *matHeaderCellDef> Client Field Name</th>
                <td mat-cell *matCellDef="let element"> {{element.clientFieldName}} </td>
              </ng-container>

              <ng-container matColumnDef="internalFieldName">
                <th mat-header-cell *matHeaderCellDef> Internal Field Name</th>
                <td mat-cell *matCellDef="let element"> {{element.internalFieldName}} </td>
              </ng-container>

              <ng-container matColumnDef="displayFieldName">
                <th mat-header-cell *matHeaderCellDef> Display Field Name</th>
                <td mat-cell *matCellDef="let element"> {{element.displayFieldName}} </td>
              </ng-container>

              <!-- <ng-container matColumnDef="calculatedFieldName">
                <th mat-header-cell *matHeaderCellDef> Calculated Field Name</th>
                <td mat-cell *matCellDef="let element"> {{element.calculatedFieldName}} </td>
              </ng-container> -->

              <ng-container matColumnDef="fieldType">
                <th mat-header-cell *matHeaderCellDef> Other Info</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.fieldType">Field Type: {{element.fieldType}}<br /> </span>
                  <span *ngIf="element.dateFormat">Date Format: {{element.dateFormat}}<br /></span>
                  <span *ngIf="element.defaultValue">Default Value: {{element.defaultValue}}<br /></span>
                  <span *ngIf="element.calculatedFieldName">Calculated Field Name:
                    {{element.calculatedFieldName}}</span>

                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="defaultValue">
                <th mat-header-cell *matHeaderCellDef>Default Value </th>
                <td mat-cell *matCellDef="let element"> {{element.defaultValue}} </td>
              </ng-container> -->

              <!-- <ng-container matColumnDef="dateFormat">
                <th mat-header-cell *matHeaderCellDef>Date Format </th>
                <td mat-cell *matCellDef="let element"> {{element.dateFormat}} </td>
              </ng-container> -->

              <ng-container matColumnDef="alias">
                <th mat-header-cell *matHeaderCellDef> Alias</th>
                <td mat-cell *matCellDef="let element"> {{element.alias}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="job.displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: job.displayedColumns;"></tr>
            </table>
            <!-- <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="showJSONContentModal(dialog, job?.settings?.api?.fieldMap)">
                <mat-icon>visibility</mat-icon>
                <span>Show JSON</span>
              </button>
            </mat-menu> -->
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div *ngIf="otherSettings?.scheduledJobs">
      <h6>Scheduled Export Jobs</h6>

      <div fxLayout="row" fxLayoutGap="10px" *ngFor="let job of otherSettings.scheduledJobs; let i = index"
        style="margin:15px 0 15px 30px;">
        <div *ngIf="job.scheduleType !== 'scheduled-once'" fxFlex="49%">
          <div>
            <h6>{{job.jobName}}</h6>
          </div>
          <div>
            <p>{{job.jobDescription}}</p>
            <p>Since {{job.createdAt | amCalendar}}
            </p>
            <button mat-button mat-raised-button (click)="showJSONContentModal(dialog, job?.jobSettings)">
              View Job Settings
            </button>
          </div>
          <div>Report sent to</div>
          <table mat-table [dataSource]="job.dataSourceJobSettings" class="mat-elevation-z8">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email</th>
              <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="job.displayedColumnsJobSettings"></tr>
            <tr mat-row *matRowDef="let row; columns: job.displayedColumnsJobSettings;"></tr>
          </table>
        </div>
        <!-- <div *ngIf="job.scheduleType !== 'scheduled-once'" fxFlex="49%">
          <pre>{{job.jobSettings | json}}</pre>
        </div> -->
      </div>
    </div>

  </div>
  <!-- <div>
    <pre>{{otherSettings | json}}</pre>
  </div> -->
</div>
