<h5>System Settings <small>(Id: {{currentUser?.clientId}})</small></h5>
<form [formGroup]="clientSettingsForm" *ngIf="clientSettingsForm">
  <!--  <span class="title"> Client Settings </span>-->
  <div fxLayout="row wrap" class="settings-row" fxLayoutGap="10px">
    <div fxFlex="65%">
      <mat-form-field appearance="outline">
        <mat-label>Client Name {{filteredTimeZones.length}} -- {{filteredTimeZones.length > 0}}</mat-label>
        <input matInput placeholder="" formControlName="clientName">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Time Zone</mat-label>
        <mat-select formControlName="clientTimezone">
          <mat-option>
            <ngx-mat-select-search [formControl]="tzFilterCtrl" [placeholderLabel]="'Search Timezones'">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let tz of filteredTimeZones" [value]="tz.key">{{tz.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Default Role for Manager Users</mat-label>
        <mat-select formControlName="defaultManagerUserRole">
          <mat-option *ngFor="let role of roles" [value]="role.roleId">{{role.roleName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Default Role for Insight Users</mat-label>
        <mat-select formControlName="defaultInsightUserRole">
          <mat-option *ngFor="let role of roles" [value]="role.roleId">{{role.roleName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Default Views for Manager Users</mat-label>
        <mat-select multiple formControlName="defaultManagerUserViewIds">
          <mat-option *ngFor="let view of views" value="{{view.viewId}}">{{view.viewName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Default Views for Insight Users</mat-label>
        <mat-select multiple formControlName="defaultInsightUserViewIds">
          <mat-option *ngFor="let view of views" value="{{view.viewId}}">{{view.viewName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Benchmarks Enable?</mat-label>
        <mat-select formControlName="hasBenchMarks">
          <mat-option *ngFor="let v of [{k :'yes', v: 'Yes'}, {k : 'no', v: 'No'}]" value="{{v.k}}">{{v.v}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Features Enabled</mat-label>
        <mat-select multiple formControlName="featureWhiteList">
          <mat-option *ngFor="let v of features" value="{{v}}">{{v | humanize}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Session Expiry</mat-label>
        <mat-select formControlName="tokenExpiryInMinutes">
          <mat-option *ngFor="let o of tokenExpiryOptions" value="{{o.k}}">{{o.v}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Email Alerts Enabled</mat-label>
        <mat-select formControlName="needsSurveyResponseEscalation">
          <mat-option [value]=1>Yes</mat-option>
          <mat-option [value]=0>No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" class="settings-row" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-checkbox formControlName="isMailBoxMonitored">Is Mail Box Monitored</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="identifyClientsInUnsubscribeLink">
        Identify Clients in Unsubscribe Link
      </mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="hideEmailTrackingCode">
        Hide Email Tracking Code
      </mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="insightsEnabled">Insights Enabled</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="weeklyEmailEnabled">Weekly Mail Enabled</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="compareWidgetsEnabled">Compare Widgets Enabled</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="smsEnabled">SMS Enabled</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="forcePwdChange" title="Force users to change password every 90 days">Force Change
        Password Enabled</mat-checkbox>
    </div>
    <div fxFlex="32%">
      <mat-checkbox formControlName="enableLiveSession" title="Track Live Session">Track Live Session</mat-checkbox>
    </div>
    <div fxFlex="64%">
      <mat-checkbox formControlName="excludeUnsubscribeLinkInEmail" [disabled]="!isSurvaleAdmin">
        Hide Unsubscribe Link in emails sent <b>IMPORTANT: Use only for Employee Campaigns</b>
      </mat-checkbox>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>SMS Number</mat-label>
        <input matInput placeholder="" formControlName="smsNumber">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Email Provider</mat-label>
        <mat-select formControlName="emailProvider">
          <mat-option value="Sendgrid">Sendgrid</mat-option>
          <mat-option value="SES">Amazon SES</mat-option>
          <mat-option value="SES_MAILER">Amazon SES using Node Mailer</mat-option>
          
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Reply To Email</mat-label>
        <input matInput placeholder="" formControlName="replyToEmail">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Reply To Name</mat-label>
        <input matInput placeholder="Name as it appears in Email From" formControlName="replyToName">
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Header: header_from</mat-label>
        <input matInput placeholder="talent.survale.com" formControlName="header_from">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Header: smtp_mailfrom</mat-label>
        <input matInput placeholder="talent.survale.com" formControlName="smtp_mailfrom">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>One click Unsubscribe Url</mat-label>
        <input matInput placeholder="talent.survale.com" formControlName="unsubscribeUrl">
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>System Generated Reply To Email</mat-label>
        <input matInput placeholder="" formControlName="systemReplyToEmail">
        <mat-hint>All the emails that are sent by the system (e.g. Dashboard Screenshot emails)</mat-hint>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>System Generated Reply To Name</mat-label>
        <input matInput placeholder="Name as it appears in Email From" formControlName="systemReplyToName">
        <mat-hint>All the emails that are sent by the system (e.g. Dashboard Screenshot emails)</mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Users</mat-label>
        <input matInput placeholder="" formControlName="users">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Views</mat-label>
        <input matInput placeholder="" formControlName="views">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input matInput placeholder="" formControlName="operatingCountry">
      </mat-form-field>
    </div>
    <!-- <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>ATS Auth Provider</mat-label>
        <mat-select formControlName="atsAuthProvider">
          <mat-option value="Smart Recruiters">Smart Recruiters</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    <!-- <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Index Calculation on Surveys Tab</mat-label>
        <mat-select formControlName="indexCalculations">
          <mat-option value="widgets">From Widgets (responsive to filters)</mat-option>
          <mat-option value="database">Locked with Dashboard</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="49%">
      <mat-form-field appearance="outline">
        <mat-label>Email Footer HTML</mat-label>
        <textarea matInput placeholder="" rows="6" formControlName="emailFooterText"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="49%">
      <mat-form-field appearance="outline">
        <mat-label>Unsubscribe Prefix</mat-label>
        <input matInput placeholder="" formControlName="unsubscribePrefix">
        <label matSuffix>Click here</label>
      </mat-form-field>
    </div>
    <!--    <div fxFlex="10%">-->
    <!--      <a></a>-->
    <!--    </div>-->
    <div fxFlex="98%">
      <label>Default Email Footer HTML </label>
      <pre>{{defaultEmailFooterText}}</pre>
    </div>
  </div>
</form>
<div fxLayout="row wrap" fxLayoutGap="10px">

</div>
