import * as _ from 'lodash';
import * as moment from 'moment';


enum CacheType {
  Views = 'views',
  ViewDashboards = 'view-dashboards',
  View = 'view',
  Survey = 'survey',
  ClientDataSet = 'client-data-set',
  Dashboard = 'dashboard',
  SelfDashboardFilters = 'self-dashboard-filters',
  SelfDashboardsFilters = 'self-dashboards-filters',
  SelfSurveyFilters = 'self-survey-filters',
  GridsterDashboard = 'gridster-dashboard',
  GridsterSurveyResults = 'gridster-survey-results',
  InsightDashboard = 'insight-dashboard',
  Insights = 'insights',
  Insight = 'insight',
  SurveyFilter = 'survey-filter',
  SurveyFilters = 'survey-filters',
  GridsterEmployeeCampaignsResults = 'gridster-employee-campaigns-results',
  SurveyResponses = 'SurveyResponses',
  SurveyDetails = 'SurveyDetails',
  LastUsedValues = 'LastUsedValues',
}

export class AppCache {
  private static instance: AppCache;
  localCache: any;
  localStorage = window.localStorage;

  private constructor() {
    this.localCache = {};
  }

  public static getInstance(): AppCache {
    if (!AppCache.instance) {
      AppCache.instance = new AppCache();
    }
    return AppCache.instance;
  }

  public static cacheTypes() {
    return CacheType;
  }

  /**
   * get's from in-memory cache
   * @param cacheType
   * @param key
   */
  public getCache(cacheType: CacheType, key: string) {
    const v = this.localCache[`cache ${cacheType.toString()}-${key}`];
    if (_.isNil(v) || _.isNil(v.expireInSeconds)) {
      return v;
    } else {
      if (typeof (v.cachedAt) === 'string') {
        v.cachedAt = new Date(v.cachedAt);
      }
      if (((new Date().getTime() - v.cachedAt.getTime()) / 1000) < v.expireInSeconds) {
        return v.value;
      } else {
        delete this.localCache[`cache ${cacheType.toString()}-${key}`];
        return undefined;
      }
    }
  }

  public resetCache() {
    this.localCache = {};
    this.localStorage.clear();
  }

  public getScreenshotURL() {
    return this.localCache.screenshorURL;
  }

  public setScreenshotURL(url) {
    this.localCache.screenshorURL = url;
  }

  public addMessage(message: string) {
    this.localCache.appMessage += (new Date()) + ' ' + message;
  }

  public getMessages() {
    return this.localCache.appMessage;
  }

  /**
   * Sets to in-memory cache
   * @param cacheType
   * @param key
   * @param value
   */
  public setCache(cacheType: CacheType, key: string, value: any) {
    this.localCache[`cache ${cacheType.toString()}-${key}`] = value;
  }

  public setCacheWithExpiry(cacheType: CacheType, key: string, value: any, expireInSeconds = 300) {
    this.localCache[`cache ${cacheType.toString()}-${key}`] = {expireInSeconds, value, cachedAt: new Date()};
  }

  /**
   * get's from local storage
   * @param cacheType
   * @param key
   */
  public getLocalCache(cacheType: CacheType, key: string): string {
    const dateCached = this.localStorage.getItem(`date-cached ${cacheType.toString()}-${key}`);
    if (_.isNil(dateCached) || dateCached && moment().diff(moment(dateCached), 'days') > 30) {
      // console.log('ckearing cache dateCached >>> ', dateCached, typeof dateCached)
      this.clearLocalCache(cacheType, key);
      return undefined;
    }
    const item = this.localStorage.getItem(`cache ${cacheType.toString()}-${key}`);
    return item === 'undefined' ? undefined : item;
  }

  // public clearAllCache(cacheType: CacheType, key: string): void {
  //   this.localStorage.clear();
  // }

  public clearLocalCache(cacheType: CacheType, key: string): void {
    this.localStorage.removeItem(`date-cached ${cacheType.toString()}-${key}`);
    this.localStorage.removeItem(`cache ${cacheType.toString()}-${key}`);
  }

  /**
   * set's from local storage
   * @param cacheType
   * @param key
   * @param value
   */
  public setLocalCache(cacheType: CacheType, key: string, value: string) {
    this.localStorage.setItem(`date-cached ${cacheType.toString()}-${key}`, moment().toISOString());
    this.localStorage.setItem(`cache ${cacheType.toString()}-${key}`, value);
  }

}
