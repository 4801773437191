// Core modules
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Third party modules
import * as _ from 'lodash';

// Application services
import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';
import { BaseHttpService } from './base-http.service';

/**
 * Client service
 */
@Injectable()
export class ClientService extends BaseHttpService {
  // Whenever this changes also change restify client-benchmark.js
  primaryIndustries = [
    {name: 'Aerospace & Defense', value: 'Aerospace & Defense'},
    {name: 'Agriculture', value: 'Agriculture'},
    {name: 'Arts & Entertainment', value: 'Arts & Entertainment'},
    {name: 'Automotive', value: 'Automotive'},
    {name: 'Aviation', value: 'Aviation'},
    {name: 'Basic Materials', value: 'Basic Materials'},
    {name: 'Biotech', value: 'Biotech'},
    {name: 'Construction', value: 'Construction'},
    {name: 'Consumer Goods', value: 'Consumer Goods'},
    {name: 'Education', value: 'Education'},
    {name: 'Energy & Utilities', value: 'Energy & Utilities'},
    {name: 'Employment Services', value: 'Employment Services'},
    {name: 'Finance & Insurance', value: 'Finance & Insurance'},
    {name: 'Food & Beverages', value: 'Food & Beverages'},
    {name: 'Government (public sector)', value: 'Government (public sector)'},
    {name: 'Health Care', value: 'Health Care'},
    {name: 'Hospitality', value: 'Hospitality'},
    {name: 'Industrial Goods', value: 'Industrial Goods'},
    {name: 'Manufacturing', value: 'Manufacturing'},
    {name: 'Non-Profit', value: 'Non-Profit'},
    {name: 'Pharmaceutical', value: 'Pharmaceutical'},
    {name: 'Real Estate', value: 'Real Estate'},
    {name: 'Services', value: 'Services'},
    {name: 'Technology', value: 'Technology'},
    {name: 'Transportation', value: 'Transportation'},
    {name: 'Travel', value: 'Travel'},
    {name: 'Other', value: 'Other'},
  ];

  companyRevenue = [
    {name: 'Under $10M', value: 'Under $10M'},
    {name: '$11M-$50M', value: '$11M-$50M'},
    {name: '$51M-$100M', value: '$51M-$100M'},
    {name: '$101M-$500M', value: '$101M-$500M'},
    {name: '$500M-$1B', value: '$500M-$1B'},
    {name: '$1.1B-$3B', value: '$1.1B-$3B'},
    {name: '$3B-$10B', value: '$3B-$10B'},
    {name: 'Over $10B', value: 'Over $10B'},
    {name: 'Unknown/Decline to State', value: 'Unknown/Decline to State'}
  ];

  globalRevenue = [
    {name: 'Under $10M', value: 'Under $10M'},
    {name: '$11M-$50M', value: '$11M-$50M'},
    {name: '$51M-$100M', value: '$51M-$100M'},
    {name: '$101M-$500M', value: '$101M-$500M'},
    {name: '$500M-$1B', value: '$500M-$1B'},
    {name: '$1.1B-$3B', value: '$1.1B-$3B'},
    {name: '$3B-$10B', value: '$3B-$10B'},
    {name: 'Over $10B', value: 'Over $10B'},
    {name: 'Unknown/Decline to State', value: 'Unknown/Decline to State'}
  ];

  employeePopulation = [
    {name: 'Up to 500', value: 'Up to 500'},
    {name: '501-2,500', value: '501-2,500'},
    {name: '2,501-5,000', value: '2,501-5,000'},
    {name: '5,001-10,000', value: '5,001-10,000'},
    {name: '10,001-25,000', value: '10,001-25,000'},
    {name: '25,001-50,000', value: '25,001-50,000'},
    {name: '50,001-100,000', value: '50,001-100,000'},
    {name: 'Over 100,000', value: 'Over 100,000'},
  ];

  globalEmployeePopulation = [
    {name: 'Up to 500', value: 'Up to 500'},
    {name: '501-2,500', value: '501-2,500'},
    {name: '2,501-5,000', value: '2,501-5,000'},
    {name: '5,001-10,000', value: '5,001-10,000'},
    {name: '10,001-25,000', value: '10,001-25,000'},
    {name: '25,001-50,000', value: '25,001-50,000'},
    {name: '50,001-100,000', value: '50,001-100,000'},
    {name: 'Over 100,000', value: 'Over 100,000'},
  ];

  averageJobReqs = [
    {name: 'Less than 10', value: 'Less than 10'},
    {name: '11-20', value: '11-20'},
    {name: '21-30', value: '21-30'},
    {name: '31-50', value: '31-50'},
    {name: '51-75', value: '51-75'},
    {name: '76-100', value: '76-100'},
    {name: 'More than 100', value: 'More than 100'},
  ];

  newHires = [
    {name: 'Up to 100', value: 'Up to 100'},
    {name: '101-200', value: '101-200'},
    {name: '201-500', value: '201-500'},
    {name: '501-1,000', value: '501-1,000'},
    {name: '1,001-2,500', value: '1,001-2,500'},
    {name: '2,501-5,000', value: '2,501-5,000'},
    {name: '5,001-10,000', value: '5,001-10,000'},
    {name: '10,001-25,000', value: '10,001-25,000'},
    {name: '25,001-50,000', value: '25,001-50,000'},
    {name: '50,001-100,000', value: '50,001-100,000'},
    {name: 'Over 100,000', value: 'Over 100,000'},
  ];

  globalNewHires = [
    {name: 'Up to 100', value: 'Up to 100'},
    {name: '101-200', value: '101-200'},
    {name: '201-500', value: '201-500'},
    {name: '501-1,000', value: '501-1,000'},
    {name: '1,001-2,500', value: '1,001-2,500'},
    {name: '2,501-5,000', value: '2,501-5,000'},
    {name: '5,001-10,000', value: '5,001-10,000'},
    {name: '10,001-25,000', value: '10,001-25,000'},
    {name: '25,001-75,000', value: '25,001-75,000'},
    {name: '25,001-50,000', value: '25,001-50,000'},
    {name: '50,001-100,000', value: '50,001-100,000'},
    {name: 'Over 100,000', value: 'Over 100,000'},
  ];

  recruitingTeamSize = [
    {name: 'Under 10', value: 'Under 10'},
    {name: '11-25', value: '11-25'},
    {name: '26-50', value: '26-50'},
    {name: '51-100', value: '51-100'},
    {name: '101-150', value: '101-150'},
    {name: '151-200', value: '151-200'},
    {name: '201-250', value: '201-250'},
    {name: 'Over 250', value: 'Over 250'},
  ];

  recruitingTeamStructure = [
    {
      name: 'Centralized (dedicated recruiters, recruiting org structure and budget)',
      value: 'Centralized (dedicated recruiters, recruiting org structure and budget)'
    },
    {
      name: 'Decentralized (division/line of business dedicated recruiters, org structure and budget)',
      value: 'Decentralized (division/line of business dedicated recruiters, org structure and budget)'
    },
    {
      name: 'Outsourced (RPO, HRO, third party vendor)',
      value: 'Outsourced (RPO, HRO, third party vendor)'
    },
    {
      name: 'Generalist (recruiting is a subset of an HR resources responsibility)',
      value: 'Generalist (recruiting is a subset of an HR resources responsibility)'
    },
    {
      name: 'A combination of Centralized and Outsourced',
      value: 'A combination of Centralized and Outsourced'
    },
    {
      name: 'A combination of Decentralized and Outsourced',
      value: 'A combination of Decentralized and Outsourced'
    },
  ];

  defaultSurveyEscalation = {
    reportToEmail: [],
    customLookoutKeyWords: [
      'worried',
      'disappointed',
      'delay',
      'delayed',
      'irresponsible',
      'non responsive',
      'unresponsive'],
    longCommentsMinChars: 50,
    schedule: 'hourly',
    hours: 1,
    minScore: 3,
    immediateAlert: {
      minScore: {enabled: true, value: 3},
      alertLongComments: {enabled: true, value: 3}
    },
    npsAlert: []
  };

  /**
   * Life cycle method
   * @param http HttpClient
   * @param configService ConfigService
   * @param authenticationService Authentication Service
   */
  constructor(
    public http: HttpClient,
    public configService: ConfigService,
    public authenticationService: AuthenticationService
  ) {
    super(http);
  }

  /**
   * Find client settings
   */
  getClientSettings(): any {
    return this.get('client-settings');
  }

  /**
   * Set client settings
   * @param payload Payload
   */
  setClientSettings(payload: any): any {
    return this.put('client-settings', payload, '');
  }

  /**
   * Set BE client settings
   * @param payload Payload
   */
  setBEClientSettings(payload: any): any {
    return this.put('be-client-settings', payload, '');
  }

  /**
   * Add client data
   * @param payload Payload
   */
  addClient(payload: any): any {
    return this.put('add-client', payload, '');
  }

  /**
   * Find client environment
   */
  getClientsEnv(): any {
    return this.get('client-env');
  }

  /**
   * Find primary industries
   */
  getPrimaryIndustries(): any {
    return this.primaryIndustries;
  }

  /**
   * Find company revenue options
   */
  getCompanyRevenue(): any {
    return this.companyRevenue;
  }

  /**
   * Find global revenue
   */
  getGlobalRevenue(): any {
    return this.globalRevenue;
  }

  /**
   * Find employee population
   */
  getEmployeePopulation(): any {
    return this.employeePopulation;
  }

  /**
   * Find global employee population
   */
  getGlobalEmployeePopulation(): any {
    return this.globalEmployeePopulation;
  }

  getAverageJobReqs(): any {
    return this.averageJobReqs;
  }

  /**
   * Find company hires
   */
  getCompanyNewHires(): any {
    return this.newHires;
  }

  /**
   * Find global new hires
   */
  getGlobalNewHires(): any {
    return this.globalNewHires;
  }

  /**
   * Find recruiting team size
   */
  getRecruitingSize(): any {
    return this.recruitingTeamSize;
  }

  /**
   * Find team structure
   */
  getRecruitingTeamStructure(): any {
    return this.recruitingTeamStructure;
  }

  /**
   * Returns default survey escalation  values
   */
  getDefaultSurveyEscalation(): any {
    return this.defaultSurveyEscalation;
  }

  getClient(): any {
    return this.get('client');
  }

  getChildClients(): any {
    const path = 'child-clients';
    return this.get(path, undefined, {ts: (new Date()).getTime()});
  }

  getSSO(): any {
    return this.get('client-saml-sso');
  }

  setSSO(payload: any): any {
    return this.put('client-saml-sso', payload, '');
  }

  getOtherClientSettings(): any {
    return this.get('client-settings-other');
  }
}
