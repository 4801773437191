import * as _ from 'lodash';
import { title } from 'process';

export class ConfigServiceData {
  public static readonly COMMON_EMAIL_FIELDS = _.sortBy(['First Name', 'Last Name', 'Email Address',
    'Hiring Manager First Name', 'Hiring Manager Last Name', 'Hiring Manager Email Address',
    'Recruiter First Name', 'Recruiter Last Name', 'Recruiter Email Address', 'Hire Date',
    'Termination Date']);
  public static readonly COMMON_EMPLOYEE_FIELDS = _.sortBy([
    'First Name', 'Last Name', 'Email Address', 'Employee ID', 'Manager Employee ID', 'Job Title', 'Gender',
    'Employee Type', 'Employee Category', 'User Name', 'Country', 'Cost Center', 'Department', 'Division',
    'Is Active', 'Office Location', 'Age Band', 'Region', 'Hire Date', 'Termination Date', 'Offer Date',
    'Manager Name', 'Manager Email']);

  // from node-restify - appUtils knownListFields
  public static readonly LIST_INDEXED_FIELDS = {
    email: 'Email Address',
    firstName: 'First Name',
    lastName: 'Last Name',
    employeeId: 'Employee ID',
    managerEmployeeId: 'Manager Employee ID',
    gender: 'Gender',
    userName: 'User Name',
    jobTitle: 'Job Title',
    country: 'Country',
    costCenter: 'Cost Center',
    isActive: 'Is Active',
    department: 'Department',
    division: 'Division',
    region: 'Region',
    ageBand: 'Age Band',
    officeLocation: 'Office Location',
    // rEmail: 'Recruiter Email Address',
    // rFName: 'Recruiter First Name',
    // rLName: 'Recruiter Last Name',
    // hMEmail: 'Hiring Manager Email Address',
    // hMFName: 'Hiring Manager First Name',
    // hMLName: 'Hiring Manager Last Name',
    hireDate: 'Hire Date',
    changeDate: 'Change Date',
    terminationDate: 'Termination Date',
    employeeType: 'Employee Type',
    employeeCategory: 'Employee Category',
    managerName: 'Manager Name',
    managerEmailAddress: 'Manager Email',
  };
  // END from node-restify - process-uploaded-email-lists.js

  public static readonly TIME_ZONES = [
    { key: 'Asia/Kabul', value: 'Afghanistan (Kabul)' }, {
      key: 'US/Alaska',
      value: 'Alaska Time'
    }, { key: 'Brazil/West', value: 'Atlantic Time' }, {
      key: 'Atlantic/Bermuda',
      value: 'Atlantic Time (Bermuda)'
    }, { key: 'Australia/Adelaide', value: 'Australian Central Time (Adelaide)' }, {
      key: 'Australia/North',
      value: 'Australian Central Time (Northern Territory)'
    }, { key: 'Australia/Queensland', value: 'Australian Eastern Time (Queensland)' }, {
      key: 'Australia/Sydney',
      value: 'Australian Eastern Time (Sydney)'
    }, { key: 'Australia/West', value: 'Australian Western Time' }, {
      key: 'Asia/Dhaka',
      value: 'Bangladesh (Dhaka)'
    }, { key: 'Europe/London', value: 'British Time (London)' }, {
      key: 'America/Buenos_Aires',
      value: 'Buenos Aires'
    }, { key: 'Africa/Cairo', value: 'Cairo' }, {
      key: 'CET',
      value: 'Central European Time'
    }, { key: 'America/Godthab', value: 'Central Greenland Time' }, {
      key: 'US/Central',
      value: 'Central Time'
    }, { key: 'America/Monterrey', value: 'Central Time (Mexico City, Monterrey)' }, {
      key: 'Canada/Saskatchewan',
      value: 'Central Time (Saskatchewan)'
    }, { key: 'Africa/Djibouti', value: 'Djibouti' }, {
      key: 'America/Sao_Paulo',
      value: 'Eastern Brazil'
    }, { key: 'EET', value: 'Eastern European Time' }, {
      key: 'America/Scoresbysund',
      value: 'Eastern Greenland Time'
    }, { key: 'US/Eastern', value: 'Eastern Time' }, {
      key: 'US/East-Indiana',
      value: 'Eastern Time (Indiana)'
    }, { key: 'GMT', value: 'Greenwich Mean Time (GMT)' }, {
      key: 'US/Hawaii',
      value: 'Hawaiian/Aleutian Time'
    }, { key: 'Asia/Hong_Kong', value: 'Hong Kong' }, {
      key: 'Asia/Kolkata',
      value: 'India (IST)'
    }, { key: 'Europe/Minsk', value: 'Minsk' }, { key: 'Europe/Moscow', value: 'Moscow' }, {
      key: 'US/Mountain',
      value: 'Mountain Time'
    }, { key: 'US/Arizona', value: 'Mountain Time (Arizona)' }, {
      key: 'Pacific/Auckland',
      value: 'New Zealand Time'
    }, { key: 'Canada/Newfoundland', value: 'Newfoundland Time' }, {
      key: 'Pacific/Norfolk',
      value: 'Norfolk Island (Austl.)'
    }, { key: 'Pacific/Noumea', value: 'Noumea, New Caledonia' }, {
      key: 'US/Pacific',
      value: 'Pacific Time(PST/PDT)'
    }, { key: 'America/Los_Angeles', value: 'US Pacific' }, {
      key: 'Pacific/Tarawa',
      value: 'Tarawa'
    }, { key: 'Asia/Tehran', value: 'Tehran' }, {
      key: 'Asia/Bangkok',
      value: 'Thailand (Bangkok)'
    }, { key: 'Asia/Tokyo', value: 'Tokyo' }, { key: 'Asia/Dubai', value: 'UAE (Dubai)' }, {
      key: 'Asia/Tashkent',
      value: 'Uzbekistan (Tashkent)'
    }, { key: 'America/Caracas', value: 'Venezuelan Standard Time (VET)' }, {
      key: 'WET',
      value: 'Western European Time'
    }, { key: 'Iceland', value: 'Western European Time (Iceland)' }, {
      key: 'Europe/Lisbon',
      value: 'Western European Time (Lisbon)'
    }, { key: 'America/Thule', value: 'Western Greenland Time' }, {
      key: 'Asia/Jakarta',
      value: 'Western Indonesian Time (Jakarta)'
    }];

  public static readonly survaleReleaseNotesLink =
    'https://sites.google.com/survale.com/survale-help-center/home';

  public static readonly survaleLiteVsEnterprise = 'https://survale.com/cande-award-survey-edition-vs-enterprise/';

  public static readonly candeVideos = [
    { id: 706744403, title: 'Survale Enterprise Overview', menuTitle: 'Survale Enterprise Overview' },
    { id: 699924326, title: 'Boost Glassdoor Ratings', menuTitle: 'Boost Glassdoor Ratings' },
    { id: 699924349, title: 'Gather Free Referrals', menuTitle: 'Gather Free Referrals' },
    { id: 735511872, title: 'Reward Talent Pool', menuTitle: 'Reward Talent Pool' },
    { id: 699924268, title: 'Career Site Optimization', menuTitle: 'Career Site Optimization' },
    { id: 699924412, title: 'Reduce Talent Ghosting', menuTitle: 'Reduce Talent Ghosting' }
         
  ];
  public static readonly businessEditionVideos = [
    { id: 1038340045, title: 'Business Edition Overview', menuTitle: 'Business Edition Overview' },
    // { id: '1038340045/c58241d03c', title: 'Business Edition Overview', menuTitle: 'Business Edition Overview' },
  ];

  public static readonly candeDashboards = [
    // {id: 1, title: 'Candidate Journey Dashboard', menuTitle: 'Candidate Journey Dashboard'},
    { id: 1, title: 'TA Leadership Dashboard', menuTitle: 'TA Leadership Dashboard' },
    { id: 2, title: 'Talent Impact Report', menuTitle: 'Talent Impact Report' },
    { id: 3, title: 'Diversity & Inclusion Report', menuTitle: 'Diversity & Inclusion Report' },
  ];

}
