// Core modules
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Angular Material and forms modules
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, AbstractControlOptions } from '@angular/forms';

// Application services
import { RolesService } from 'app/services/roles.service';
import { ViewService } from 'app/services/view.service';
import { UsersService } from 'app/services/users.service';
import { CustomValidator, MyErrorStateMatcher } from 'app/shared/validators/custom-validator';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '@services/config.service';
import { AuthenticationService } from '@services/authentication.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { LocalStorageService } from 'angular-2-local-storage';
import { SurvaleCommonComponent } from '@common/survale-common.component';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { ClientService } from '@services/client.service';
import * as humanizeString from 'humanize-string';


/**
 * Add/edit user component
 */
@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent extends SurvaleCommonComponent implements OnInit {

  public addEditForm: FormGroup;
  public invalidPassword = false;
  public isEdit = false;
  public roles: any;
  public selectedRole = '';
  public selectedView: string[];
  public userId: string;
  public views: any;
  public apiUser: any;
  public authProviderOptions = [{ title: 'Survale', authUrl: '/login' }];
  matcher = new MyErrorStateMatcher();
  uploader: FileUploader;
  showSideUploadButton = false;
  private readonly authProviderMap = {};
  private editingUser;

  /**
   * Life cycle method
   */
  constructor(
    private fb: FormBuilder,
    private roleService: RolesService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private userService: UsersService,
    private toaster: ToastrService,
    private viewService: ViewService,
    public authenticationService: AuthenticationService,
    private configService: ConfigService,
    private clientService: ClientService,
    private localStorageService: LocalStorageService,
  ) {
    super(authenticationService);
    this.authProviderMap = ConfigService.authProviderMap();
  }

  get firstName(): AbstractControl {
    return this.addEditForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.addEditForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.addEditForm.get('email');
  }

  get emailAlias(): AbstractControl {
    return this.addEditForm.get('emailAlias');
  }

  get jobTitle(): AbstractControl {
    return this.addEditForm.get('jobTitle');
  }

  get userType(): AbstractControl {
    return this.addEditForm.get('userType');
  }

  get userStatus(): AbstractControl {
    return this.addEditForm.get('userStatus');
  }

  get roleId(): AbstractControl {
    return this.addEditForm.get('roleId');
  }

  get viewIds(): AbstractControl {
    return this.addEditForm.get('viewIds');
  }

  get preferences_weekly_email(): AbstractControl {
    return this.addEditForm.get('preferences_weekly_email');
  }

  get authProvider(): AbstractControl {
    return this.addEditForm.get('authProvider');
  }

  get password(): AbstractControl {
    return this.addEditForm.get('password');
  }

  get repeatPassword(): AbstractControl {
    return this.addEditForm.get('repeatPassword');
  }

  get avatar(): AbstractControl {
    return this.addEditForm.get('avatar');
  }

  get isSpecialUserWoEmail(): boolean {
    const email = _.get(this.editingUser, 'email', '');
    return !_.isNil(this.userId) && email.indexOf('@') === -1;
  }

  /**
   * Initialize addEditForm and get user id if exist and get the user data by user id
   */
  async ngOnInit(): Promise<void> {
    // initialize form
    this.userId = this.route.snapshot.paramMap.get('id');
    const cu = this.authenticationService.getCurrentUser();
    const clientSettings: any = (await firstValueFrom(this.clientService.getClientSettings()) as any).body[0];
    if (this.authProviderMap[cu.clientId]) {
      this.authProviderOptions =
        this.authProviderOptions.concat(this.authProviderMap[this.authenticationService.getCurrentUser().clientId]);
    }
    // console.log('clientSettings.ssoAuthName', clientSettings.ssoAuthName,
    //   this.authProviderOptions, this.authProviderMap, this.authenticationService.getCurrentUser().clientId);
    if (clientSettings.ssoAuthName) {
      this.authProviderOptions = _.uniqBy(
        this.authProviderOptions.concat([
          { title: clientSettings.ssoAuthTitle, authUrl: `v0/auth/${clientSettings.ssoAuthName}` }
        ]), 'title'
      );
    }

    // console.log('this.authProviderOptions', this.authProviderOptions);

    this.initializeForm();
    if (this.userId) {
      this.userService.getUser(this.userId)
        .subscribe((user: any): void => {
          // console.log('user.body[0]', user.body[0]);
          const val = user.body[0];
          if (typeof val.viewIds === 'string') {
            val.viewIds = val.viewIds.split(',');
          }
          val.roleId = val.roleId.toString();
          this.editingUser = val;
          val.authProvider = val.authProvider || 'Survale';
          val.preferences_weekly_email = this.editingUser.preferences_weekly_email || 'yes';
          // console.log('this.editingUser', this.editingUser);
          this.addEditForm.patchValue(val);
          if (this.isSpecialUserWoEmail) {
            // console.log('this.addEditForm.controls.email.validator', this.addEditForm.controls.email.validator)
            this.email.clearValidators();
          } else {
            this.email.setValidators([Validators.required, Validators.email]);
          }
        });
    } else {
      this.email.setValidators([Validators.required, Validators.email]);
    }
    this.email.updateValueAndValidity();
    this.userService.apiUser()
      .subscribe((r: any): void => {
        this.apiUser = r.body.apiUser;
      });
    this.initializeUploadForm();
  }

  /**
   * Initialize upload form
   */
  initializeUploadForm(): void {
    const config = ConfigService.config;
    const authTokenKey = this.configService.localStorageKeys.AuthToken;
    const apiKey: string = this.localStorageService.get(authTokenKey);
    const url = config.hostUri + `/v0/upload/?apiKey=${apiKey}&survaleFileType=image`;

    this.uploader = new FileUploader({
      url,
      isHTML5: true
    });
  }

  /**
   * Upload file
   */
  uploadFile(event: any): void {
    this.uploader.queue[0].withCredentials = false;
    this.toaster.info('Uploading. Please wait.');
    this.uploader.queue[0].upload();
    this.uploader.onErrorItem = (item, response): void => this.onErrorItem(item, response);
    this.uploader.onSuccessItem = (item, response): void => this.onSuccessItem(item, response, event.target.files[0]);
  }

  /**
   * Success call back for file uplaod
   * @param item FileItem
   * @param response String
   */
  onSuccessItem(item: FileItem, response: string, files: any): any {
    const data = JSON.parse(response); // success server response
    this.showSideUploadButton = false;
    this.avatar.setValue(data.data.Location);
  }

  /**
   * Error call back
   * @param item FileItem
   * @param response string
   */
  onErrorItem(item: FileItem, response: string): any {
    const error = JSON.parse(response); // error server response
    console.error(item, response, error);
    this.uploader.clearQueue();
  }

  /**
   * Initialize Form
   */
  initializeForm(): void {
    const validator = this.userId ? undefined : CustomValidator.passwordErrorValidator;
    // const emailValidators = this.isSpecialUserWoEmail ? undefined : [Validators.required, Validators.email];
    this.addEditForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      emailAlias: new FormControl(''),
      jobTitle: new FormControl(''),
      userType: new FormControl(''),
      userStatus: new FormControl('active'),
      roleId: new FormControl(0, [Validators.required]),
      viewIds: new FormControl(''),
      preferences_weekly_email: new FormControl('yes', [Validators.required]),
      authProvider: this.authProviderOptions && this.authProviderOptions.length > 0 ?
        new FormControl('', [Validators.required]) : undefined,
      password: this.userId ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(8)]),
      repeatPassword: this.userId ? new FormControl('') : new FormControl('', [Validators.required, Validators.minLength(8),]),
      avatar: new FormControl(''),
    },
      {
        validator
      } as AbstractControlOptions
    )
      ;
    if (this.userId) {
      delete this.addEditForm.controls.password;
      delete this.addEditForm.controls.repeatPassword;
    }
    if (!this.isBE()) {
      this.roleService.getRoles().subscribe((roles): void => this.roles = roles.body);
    }
    this.viewService.getAllViews().subscribe((views): void => this.views = views.body);
  }

  removePhoto(): void {
    this.avatar.setValue('');
  }

  createAPIUser(): void {
    if (!confirm('Are you sure you want to create a API User?')) {
      return;
    }
    const guid = Date.now().toString(36) + Math.random().toString(36).substring(2);
    const uName = Math.random().toString(36).substring(10, 5);
    this.addEditForm.patchValue(
      {
        firstName: 'api_user_survale',
        lastName: 'api_user_survale',
        email: `s+aus-${this.currentUser.clientId}-${uName}@survale.com`,
        emailAlias: `s+aus-${this.currentUser.clientId}-${uName}@survale.com`,
        jobTitle: 'api_user_survale',
        userStatus: 'active',
        preferences_weekly_email: 'yes',
        password: guid,
        repeatPassword: guid
      }
    );
    this.toaster.info('Assign appropriate Role and save user');
  }

  /**
   * Add the user in the system
   */
  saveUser(): void {
    // console.log(this.addEditForm.value, this.addEditForm.valid);
    if (!this.addEditForm.valid) {
      this.validateAllFormFields();
      this.toaster.error('Please fix errors');
    } else {
      if (!this.userId) {
        this.userService.addUser(this.addEditForm.value)
          .subscribe(
            {
              next: (): void => {
                this.toaster.success('User added successfully');
                this.router.navigate(['admin/users']);
              },
              error: (err): any => this.toaster.error(`Error: ${err.error.error}`)
            });
      } else {
        this.userService.editUser(this.userId, this.addEditForm.value)
          .subscribe({
            next: (): void => {
              this.toaster.success('User updated successfully');
              this.router.navigate(['admin/users']);
            },
            error: (err): any => this.toaster.error(`Error: ${err.error.error}`)
          });
      }
    }
  }

  /**
   * Validate all form fields
   */
  validateAllFormFields(): void {
    Object.keys(this.addEditForm.controls).forEach((field): void => {
      const control = this.addEditForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
