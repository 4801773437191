// Core modules
import { Component, OnInit } from '@angular/core';

// Application services
import { UsersService, AuthenticationService, ConfigService } from '../../../services/services.module';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { TwoFaCodeComponent } from './two-fa-code/two-fa-code.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';


/**
 * User list component
 */
@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends SurvaleCommonComponent implements OnInit {

  public users: any = [];
  public displayedUsers: any = [];
  public currentPage = 1;
  public pageSize = 15;
  public searchTerm = '';
  private readonly searchUserList: () => void;

  /**
   * Life cycle method
   */
  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public authenticationService: AuthenticationService) {
    super(authenticationService);
    this.searchUserList = _.debounce((): void => this.debouncedSearchUserList(), 500);

  }

  /**
   * Initialize users list
   */
  ngOnInit(): void {
    this.getUsersList();
  }

  /**
   * Get all users
   */
  async getUsersList(): Promise<void> {
    const data = await firstValueFrom(this.usersService.getUsers(this.searchTerm));

    this.users = data.body;
    await this.changePage(0);
  }

  async getLoginHistory(user: any): Promise<void> {
    if (user.history) {
      return;
    }
    const cached = SurvaleCommonComponent.getInMemoryCache('loginHistory', user.userId);
    if (cached) {
      user.history = cached;
    } else {
      const history = await firstValueFrom(this.usersService.getLoginHistory(user.emailAlias || user.email));
      user.history = history.body.history;
      SurvaleCommonComponent.setImMemoryCache('loginHistory', user.userId, user.history);
    }

  }

  searchUsers(filter: any): void {
    this.getUsersList();
  }

  async changePage(val: number):  Promise<void> {
    this.currentPage = this.currentPage + val;
    this.displayedUsers = this.users.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    for (const user of this.displayedUsers) {
      await this.getLoginHistory(user);
    }
  }

  debouncedSearchUserList(): void {
    // this.listParams.filter = this.searchTerm;
    // this.listParams.tags = this.selectedTags;
    // this.listParams.offset = 0;
    // this.paginator ? this.paginator.firstPage() : _.noop();
    // this.loadSurveys().then(_.noop);
    this.getUsersList().then(_.noop);

  }
  setTwoFactorAuthentication(user: any): void {
    const opts = _.merge({ data: { user } }, ConfigService.getDialogConfigWide());
    const d = this.dialog.open(TwoFaCodeComponent, opts);
    d.afterClosed().subscribe((result): Promise<void> => this.getUsersList());
  }

  setPassword(user: any): void {
    const opts = _.merge({ data: { user } }, ConfigService.getDialogConfigSmall());
    const d = this.dialog.open(SetPasswordComponent, opts);
    d.afterClosed().subscribe((result): Promise<void> => this.getUsersList());
  }

  async deleteUser(user: any): Promise<void> {
    if (confirm(` Are you sure to delete user ${user.firstName} ${(user.lastName || '')}?`)) {
      try {
        const data = await firstValueFrom(this.usersService.deleteUser(user.userId));
        this.toastr.success('User deleted successfully');
        this.getUsersList();
      } catch (e) {
        this.toastr.error('Error deleting user');
      }
    }
  }
}
