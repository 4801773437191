
import { Component, Input, OnInit } from '@angular/core';

@Component({
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
  selector: 'app-menu-items'
})

export class MenuItemsComponent {
  @Input() menuItems: any;
  constructor() {
  }
}
