import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConfigService, PermissionOptions, PermissionTypes } from './config.service';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { SurvaleHttpInterceptor } from '../interceptors/survale-http-interceptor';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import { AppCache } from '../components/survale-common/cache/app.cache';
import { EventTypes } from './google-analytics.service';
import ls from '@livesession/sdk';
import { productFruits } from 'product-fruits';

declare let gtag: (type: string, name: string, options: object) => void;

declare global {
  interface Window {
    productFruits: any;
  }
}
interface PfWindow {
  productFruits: any;
}

@Injectable()
export class AuthenticationService extends BaseHttpService {
  public onCurrentUserChanged$: EventEmitter<any>;
  // private currentUser: any;
  private readonly currentUserKey: string;
  private currentUser: any = null;

  constructor(private survaleHttpInterceptor: SurvaleHttpInterceptor,
    public http: HttpClient,
    public configService: ConfigService,
    private router: Router,
    private localStorageService: LocalStorageService) {
    super(http);
    this.onCurrentUserChanged$ = new EventEmitter<any>();
    this.currentUserKey = this.configService.localStorageKeys.CurrentUser;
    const currentUser = this.localStorageService.get(this.currentUserKey);
    this.setCurrentUser(currentUser);
  }

  login(userName: string, password: string, mfaCode: string): Observable<any> {
    return new Observable(observer => {
      this.post('login-user', { userName, password, mfaCode })
        .subscribe(
          {
            next: (response: any) => {
              // TODO: Cache the data locally
              if (response.body.id === 400 && _.isUndefined(response.body.userId)) {
                // this is an error
                // console.log('response.body', response.body);
                const errorStr = typeof response.body.error === 'string' ? response.body.error : 'Invalid username and password';
                observer.error({ error: errorStr });
                observer.complete();
                return;
              }
              const currentUser = response.body;
              this.setCurrentUser(currentUser);
              // console.log('login-user', response);
              observer.next(response);
              observer.complete();
            },
            error: ((error): any => {
              console.error('Error in login', error);
              observer.error(error);
              observer.complete();
            })
          });
    });
  }

  verifyEmail(emailAddress: string, captcha: string) {
    return this.post('verify-email', { emailAddress, captcha, key: '-key3' });
  }

  loginWithTempToken(tempToken: string): any {
    return this.post('login-user-w-temp-token', { tempToken });
  }

  async setAuthToken(loginToken) {
    // console.log('setting authToken', loginToken)
    this.survaleHttpInterceptor.setAuthToken(loginToken);
    await this.validateSession().toPromise();
  }

  async clearCurrentUser() {
    const cache = AppCache.getInstance();
    try {
      await this.post('logout-user', {}).toPromise();
    } catch (error) {
      console.error('Error on post logout-user');
    }
    try {
      window.productFruits.services.destroy();
    } catch (error) {
      console.error('Error killing product fruit');
    }
    try {
      this.localStorageService.clearAll('cache.*');
      const keys = this.localStorageService.keys();
      // console.log('this.localStorageService.clearAll', keys);
    } catch (error) {
      console.error('Error clearing localStorageService');
    }
    cache.resetCache();
  }

  updateCurrentUser(currentUser) {
    this.currentUser.clientSettings = {...this.currentUser.clientSettings, ...currentUser.clientSettings};
    // this.currentUser = {...this.currentUser, ...currentUser};
    this.localStorageService.set(this.currentUserKey, this.currentUser);
  }

  setCurrentUser(currentUser) {

    if (!currentUser) {
      return;
    }
    const authToken = _.get(currentUser, 'loginToken');
    if (authToken) {
      this.survaleHttpInterceptor.setAuthToken(authToken);
      // console.log('setting authToken _.get(currentUser, \'loginToken\')', _.get(currentUser, 'loginToken'))
      // } else {
      //   console.log('currentUser', currentUser);
    }
    this.currentUser = currentUser;

    const propsToTrack = ['clientName', 'clientSource', 'firstName', 'lastName', 'parentClientId', 'roleName', 'userType'];
    const isBE = this.currentUser.clientType === 'BE_PAID_CUSTOMER';
    const enableLiveSession = (_.get(this.currentUser, 'clientSettings.enableLiveSession', false) || isBE) &&
      this.currentUser.email.indexOf('survale.com') === -1 &&
      environment.name === 'prod';
    console.log(' this.currentUser.email', this.currentUser.email, enableLiveSession, environment.name);
    if (enableLiveSession) {
      try {
        this.currentUser.name = `${this.currentUser.firstName} ${this.currentUser.lastName}`;
        const liUser = _.assign(_.pick(this.currentUser, ['name', 'email']), { params: _.pick(this.currentUser, propsToTrack) });
        ls.init('8757a2b4.90582847', { keystrokes: false });
        ls.identify(liUser);
        ls.newPageView();
        ls.newPageView();
      } catch (error) {
        console.error('Cannot load Live Session', error);
      }
    }
    // if (this.configService.getConfig().survaleEnv === 'staging') {
    // console.log('setting up product fruit');
    this.initProductFruit(0, this.currentUser);
    // } else {
    //   console.log('skipping product fruit');
    // }

    this.localStorageService.set(this.currentUserKey, this.currentUser);
    if (this.currentUser) {
      // this.currentUser.isSurvaleAdmin = true;
      this.currentUser.isSurvaleAdmin = this.currentUser.email.indexOf('@survale.com') > -1;
      this.currentUser.clientTypes = this.currentUser.clientType ? this.currentUser.clientType.split(',') : [];
      // console.log('this.currentUser', this.currentUser)
    }
    this.onCurrentUserChanged$.emit(this.currentUser);
    // // @ts-ignore
    // if (typeof (__insp) !== 'undefined' && environment.enableInspectLet) {
    //   const inspectLetFields = ['clientName', 'clientSource', 'userName', 'email'];
    //   // @ts-ignore
    //   __insp.push(['tagSession', _.pick(this.currentUser, inspectLetFields)]);
    // }

  }

  initProductFruit(retryAttempt, userObj) {
    try {
      retryAttempt++;
      const userInfo = {
        username: this.currentUser.userId, // REQUIRED, must be unique
        // email: this.currentUser.email, Don' Send because of eu DPA issue
        firstname: this.currentUser.firstName,
        // lastname: this.currentUser.lastName, Don' Send because of eu DPA issue
        role: this.currentUser.roleName,
        props: {
          clientSource: this.currentUser.clientSource,
          clientType: this.currentUser.clientType,
          clientName: this.currentUser.clientName,
          userType: this.currentUser.userType,
          featuresEnabled: this.currentUser.featureWhitelist,
        }
      };


      if (!_.isNil(productFruits)) {
        const pfCode = window.location.hostname === 'pro.survale.com' ?
          ConfigService.config.productFruitCodePro : ConfigService.config.productFruitCode;
        // console.log('ConfigService.config.productFruitCode', ConfigService.config.productFruitCode);
        const options = { disableLocationChangeDetection: false };
        productFruits.init(pfCode, 'en', userInfo, options);
        // console.log('product fruit initiated', userInfo);
        // productFruits.safeExec($pf => {
        //   // console.log('$pf', $pf);
        // })
      } else {
        console.log('productFruits not defined');
        if (retryAttempt < 5) {
          setTimeout((): void => {
            this.initProductFruit(retryAttempt, userObj);
          }, 100);
        }
      }
    } catch (error) {
      console.error('Cannot load productFruits', error);
      if (retryAttempt < 5) {
        setTimeout((): void => {
          this.initProductFruit(retryAttempt, userObj);
        });
      }
    }
  }

  validateSession(): Observable<any> {
    console.log('validateSession');
    // let redirected = false;
    return new Observable(observer => {
      // try {
      let completed = false;
      this.get('session/validate')
        .subscribe
        ({
          next: (response: any) => {
            // redirected = true;
            console.log('validateSession', response);
            const currentUser = response.body;
            this.setCurrentUser(currentUser);
            // if (!_.isEmpty(this.currentUser)) {
            //   this.router.navigate(['/', 'home']).then(r => console.log('navigated home'));
            // }
            completed = true;
            observer.next(response);
            observer.complete();
          },
          error: (error) => {
            // redirect to login if validate session fails
            // redirected = true;
            console.log('validateSession error', error);
            this.clearCurrentUser().then(_.noop);
            this.router.navigate(['/', 'authentication', 'login']).then(_.noop);
            completed = true;
            observer.next(null);
            observer.complete();
          },
          complete: (): void => {
            // console.log('validateSession finished');
            if (!completed) {
              observer.next(null);
              observer.complete();
            }
          }
        });
      // } catch (error) {
      //   console.error('Error in validateSession', error);
      //   observer.error(error);
      //   observer.complete();
      // }
    });
  }

  async logout() {
    // logout-user
    await this.clearCurrentUser();
    await this.router.navigate(['/', 'authentication', 'login']);

  }

  // checkLoggedIn() {
  //   if (_.isEmpty(this.currentUser)) {
  //     this.router.navigate(['/', 'authentication', 'login']);
  //   }
  // }

  getCurrentUser(): any {
    return this.currentUser;
  }

  /**
   * Send reset email link
   * @param payload email id
   */
  forgotPassword(payload): any {
    // console.log('send-reset-link', payload);
    return this.post('send-reset-link', payload);
  }

  /**
   * Change password when user is logged in
   * @param payload password data
   */
  updatePassword(payload): any {
    if (payload.id) {
      return this.post('session/update-password', payload);
    } else {
      return this.post('session/change-password', payload);
    }
  }

  /**
   * Reset new password
   * @param payload new password
   */
  resetPassword(payload): any {
    return this.post('reset-password', payload);
  }

  public trackGAEvent(eventName: EventTypes, eventCategory: string, eventLabel: string, eventAction: string, eventValue: number) {
    // console.log('tracked event', eventName);
    gtag('event', eventName, {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,

    });
  }
}
