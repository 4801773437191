// Core modules
import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators, AbstractControl, FormArray} from '@angular/forms';
import {GeneralDataService, PermissionsService, RolesService} from 'app/services/services.module';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {firstValueFrom} from 'rxjs';

/**
 * Add Edit role component
 */
@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss']
})
export class AddEditRoleComponent implements OnInit {

  public roleGroup: FormGroup;
  public permissions: any[];
  public displayedColumns: string[] = ['permissions', 'controls'];
  public roleId;
  customFields: any;
  opField: any;
  hiddenOperationalFields: any[];

  /**
   * Life cycle method
   */
  constructor(
    private fb: FormBuilder,
    private permissionsService: PermissionsService,
    private rolesService: RolesService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private generalDataService: GeneralDataService
  ) {
  }

  /**
   * Getter for properties list
   */
  get permJSON() {
    return this.roleGroup.get('permJSON') as FormGroup;
  }

  /**
   * Return Role Name
   */
  get roleName(): AbstractControl {
    return this.roleGroup.get('roleName');
  }

  get hiddenFieldsJSON(): FormArray {
    return this.roleGroup.get('hiddenFieldsJSON') as FormArray;
  }


  addField(field) {
    if (field) {
      this.hiddenFieldsJSON.push(this.fb.control(field));
      this.hiddenOperationalFields.push(field);
      this.roleGroup.get('opField').reset();
    }
  }

  removeField(index: number) {
    this.hiddenFieldsJSON.removeAt(index);
    this.hiddenOperationalFields.splice(index, 1);
  }



  /**
   * Initializes role form
   */
  async ngOnInit(): Promise<void> {
    this.getPermissions();
    this.roleGroup = this.fb.group({
      roleName: new FormControl('', [Validators.required]),
      permJSON: this.fb.group({}),
      opField: [''],
      hiddenFieldsJSON: this.fb.array([])
    });

    this.initializePermissions();

    this.roleId = this.route.snapshot.paramMap.get('id');
    if (this.roleId) {
      this.rolesService.getRole(this.roleId).subscribe((role: any) => {
        if (role.body[0].hiddenFieldsJSON === null) {
          role.body[0].hiddenFieldsJSON = [];
        }
        this.initializeHiddenFields(role.body[0].hiddenFieldsJSON);

        this.roleGroup.patchValue(role.body[0]);
        this.hiddenOperationalFields = role.body[0].hiddenFieldsJSON === null ? [] : role.body[0].hiddenFieldsJSON;
      });
    } else {
      this.hiddenOperationalFields = [];
    }

    const r: any = await firstValueFrom(this.generalDataService.getCustomFields('all'));
    this.customFields = r.body;
    // console.log('this.customFields ', this.customFields);
    this.customFields.push({key: 'firstName', value: 'First Name', hash: 1});
    this.customFields.push({key: 'lastName', value: 'Last Name', hash: 2});
    this.customFields.push({key: 'emailAddress', value: 'Email Address', hash: 3});
    this.customFields.push({key: 'jobTitle', value: 'Job Title', hash: -1625529189});
    this.customFields = _.uniqBy(this.customFields, (cf: any): any => cf.hash);
    // const hidden = ['hiringManager', 'recruiter'];
    // this.customFields = _.filter(this.customFields, cf => hidden.indexOf( cf.key) === -1);
    this.customFields = _.sortBy(this.customFields, (cf): any => cf.value);
  }

  initializeHiddenFields(hiddenFields) {
    _.each(hiddenFields, hf => {
      this.hiddenFieldsJSON.push(this.fb.control(hf));
    });
  }

  initializePermissions() {
    this.permissions.forEach(item => {
      this.permJSON.addControl(item, new FormGroup({
        hasEdit: new FormControl(false),
        hasView: new FormControl(false),
        hasDisable: new FormControl(false)
      }));
    });
  }

  /**
   * Return all permissions
   */
  getPermissions(): any {
    this.permissions = this.permissionsService.getPermissions();
  }

  /**
   * Set disable view if hasView and hasEdit both are true
   */
  setDisableView(): void {
    Object.keys(this.permJSON.value).map(elem => {
      const element = this.permJSON.get(elem);
      this.permJSON.get(elem).get('hasDisable').patchValue(element.value.hasView && element.value.hasEdit);
    });
  }

  /**
   * Add role for user
   */
  saveRole(): void {
    this.setDisableView();
    const role = _.cloneDeep(this.roleGroup.value);
    const permJSON = _.cloneDeep(role.permJSON);
    _.each(permJSON, (v, k) => {
      const nv = _.cloneDeep(v);
      nv.disableView = v.hasEdit;
      delete nv.hasDisable;
      role.permJSON[k] = nv;
    });
    if (!this.roleId) {
      this.rolesService.addRole(role)
        .subscribe({
          next: (result: any): void => {
            this.toaster.success('Role added successfully');
            this.router.navigate(['admin/roles']);
          },
          error: ((error): any => {
            console.error(error);
            this.toaster.error('Error(s) Occurred');
          })
        });
    } else {
      this.rolesService.editRole(role, this.roleId)
        .subscribe({
          next: (result: any): void => {
            this.toaster.success('Role updated successfully');
            this.router.navigate(['admin/roles']);
          },
          error: ((error): any => {
            console.error(error);
            this.toaster.error('Error(s) Occurred');
          })
        });
    }
  }
}
