// Core modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';

// Third party module
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
// Application services
import { ClientService, AuthenticationService, ConfigService } from '../../../services/services.module';
import { AddClientDialogComponent } from '../add-client-dialog/add-client-dialog.component';
import { AddSamlSSODialogComponent } from '../add-saml-sso-dialog/add-saml-sso-dialog.component';
import { ClientSettingsComponent } from '../client-settings/client-settings.component';
import { ClientClassificationComponent } from '../client-classification/client-classification.component';
import { EmailSettingsComponent } from '../email-settings/email-settings.component';
import { ImmediateSettingsComponent } from '../immediate-settings/immediate-settings.component';
import { ClientIndexWeightsComponent } from '../client-index-weights/client-index-weights.component';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { ToastrService } from 'ngx-toastr';
import { ClientIpWhitelistComponent } from '../client-ip-whitelist/client-ip-whitelist.component';
import { OtherClientSettingsComponent } from '../other-client-settings/other-client-settings.component';

/**
 * Client component
 */
@Component({
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent extends SurvaleCommonComponent implements OnInit {

  @ViewChild(ClientSettingsComponent) clientSettingComponent: ClientSettingsComponent;
  @ViewChild(ClientClassificationComponent) clientClassificationComponent: ClientClassificationComponent;
  @ViewChild(ImmediateSettingsComponent) immediateSettingsComponent: ImmediateSettingsComponent;
  @ViewChild(EmailSettingsComponent) emailSettingsComponent: EmailSettingsComponent;
  @ViewChild(ClientIndexWeightsComponent) clientIndexWeightsComponent: ClientIndexWeightsComponent;
  @ViewChild(ClientIpWhitelistComponent) clientIpWhitelistComponent: ClientIpWhitelistComponent;
  // @ViewChild(OtherClientSettingsComponent) otherClientSettingsComponent: OtherClientSettingsComponent;
  public clientSettings: any;
  public completeClientSettings: any;
  ipWhiteList: string;
  clientAPIKeys: { userId: number; userName: string; enabled: boolean; key: string; created: string };
  useIpWhiteList: number;
  drillDownFloorLimit: number;
  dataRetentionRules: any;
  private dialogRef: any;

  /**
   * Life cycle method
   */
  constructor(
    public authenticationService: AuthenticationService,
    private clientService: ClientService,
    private configService: ConfigService,
    public dialog: MatDialog,
    private toaster: ToastrService,
  ) {
    super(authenticationService);
  }

  /**
   * Life cycle init method , find client settings
   */
  ngOnInit(): void {
    this.loadClientSettings();
  }

  /**
   * Load client settings
   */
  loadClientSettings(): void {
    this.clientService.getClientSettings()
      .subscribe((data: any) => {
        const clientData = data.body[0];
        this.clientSettings = JSON.parse(clientData.clientSettings);
        this.useIpWhiteList = clientData.useIpWhiteList;
        this.drillDownFloorLimit = clientData.drillDownFloorLimit;
        this.dataRetentionRules = clientData.dataRetentionRules;
        this.ipWhiteList = clientData.ipWhiteList;
        this.clientAPIKeys = clientData.clientAPIKeys;
        if (typeof this.clientAPIKeys === 'string') {
          this.clientAPIKeys = JSON.parse(this.clientAPIKeys);
        }

        this.clientSettings = this.clientSettings || {};
        // console.log('this.clientSettings', this.clientSettings, this.useIpWhiteList, this.ipWhiteList);
        this.clientSettings.users = clientData.licensedUsers;
        this.clientSettings.views = clientData.licensedViews;
        this.clientSettings.clientName = clientData.clientName;
        this.clientSettings.clientTimezone = clientData.clientTimezone;
        this.clientSettings.needsSurveyResponseEscalation = clientData.needsSurveyResponseEscalation;
      });
  }

  /**
   * Open Add client dialog and save client data when dialog close
   */
  showAddClient(): void {
    this.dialogRef = this.dialog.open(AddClientDialogComponent, ConfigService.getDialogConfigWide());
    let flag: any;
    this.dialogRef.afterClosed().pipe(
      switchMap((result: FormGroup) => {
        console.log('result', result);
        if (result) {
          flag = result;
          return this.clientService.addClient(result.value);
        }
      })
    ).subscribe((): void => {
      if (flag) {
        this.toaster.success('Client Added Successfully');
      }
    }, ((error): any => {
      console.error(error);
      if (error.error && error.error.errorCode === 5001) {
        this.toaster.error('A client with this name already exists');
      } else {
        this.toaster.error(`Error(s) occurred while adding client ${JSON.stringify(error)}`);
      }
    }));
  }

  showAddSamlSSO(): void {
    this.dialogRef = this.dialog.open(AddSamlSSODialogComponent, ConfigService.getDialogConfigWide());
  }

  /**
   * Save client settings
   */
  saveSettings(): void {
    this.completeClientSettings = this.clientSettingComponent.clientSettingsForm.value;
    const { header_from, smtp_mailfrom, unsubscribeUrl } = this.clientSettingComponent.clientSettingsForm.value;
    this.completeClientSettings.sesExConfig = 
    {
      header_from,
      smtp_mailfrom,
      unsubscribeUrl
    };
    this.completeClientSettings.clientClassification = this.clientClassificationComponent.clientClassificationForm.value;
    this.completeClientSettings.socialMediaUrls = this.clientClassificationComponent.socialMediaForm.value;
    this.completeClientSettings.logos = this.clientClassificationComponent.clientLogoForm.value;
    this.completeClientSettings.otherSettings = this.clientClassificationComponent.otherSettingsForm.value;
    
    // socialMediaForm
    if (this.isSurvaleAdmin) {
      this.completeClientSettings.clientTypeSettings = this.clientClassificationComponent.clientTypeForm.value;
    }
    this.completeClientSettings.surveyEscalation = this.emailSettingsComponent.surveyEscalationSettings || null;
    // console.log('this.completeClientSettings.emailSettingsComponent.form.value', this.emailSettingsComponent.form.value);
    for (const val of 'hours,schedule,npsAlert,longCommentsMinChars'.split(',')) {
      this.completeClientSettings.surveyEscalation[val] = this.emailSettingsComponent.form.value[val];
    }
    // console.log('this.completeClientSettings.surveyEscalation', this.completeClientSettings.surveyEscalation);
    this.completeClientSettings.surveyEscalation.immediateAlert = this.immediateSettingsComponent.immediateAlertForm.value;
    // console.log('this.immediateSettingsComponent.immediateAlertForm.value', this.immediateSettingsComponent.immediateAlertForm.value);
    const indexWeights = this.clientIndexWeightsComponent.indexWeights;
    this.completeClientSettings.indexWeights = _.compact(_.map(indexWeights, i => i.keyword ? i : undefined));
    const whiteListArr = ['employeeSensitiveFields', 'candidateSensitiveFields', 'widgetBlackList',
      'drillDownFloorLimit', 'dataRetentionRules', 'candidateSensitiveFieldsExports', 'employeeSensitiveFieldsExports',
      'slackBotSettings', 'operationalFieldAliases', 'webHookType'];
    _.each(whiteListArr, v => this.completeClientSettings[v] = this.clientSettings[v]);
    console.log('this.completeClientSettings', this.completeClientSettings);
    const client: any = {};
    client.settings = this.completeClientSettings;
    client.licensedUsers = this.completeClientSettings.users;
    client.licensedViews = this.completeClientSettings.views;
    client.clientName = this.completeClientSettings.clientName;
    client.clientTimezone = this.completeClientSettings.clientTimezone;
    client.needsSurveyResponseEscalation = this.completeClientSettings.needsSurveyResponseEscalation;
    client.useIpWhiteList = this.clientIpWhitelistComponent.useIpWhiteList;
    client.ipWhiteList = this.clientIpWhitelistComponent.ipWhiteList;
    client.clientAPIKeys = this.clientIpWhitelistComponent.clientAPIKeys;
    this.clientService.setClientSettings(client)
      .subscribe((): void => {
        this.currentUser.clientSettings = client.settings;
        this.toaster.success('Client settings saved successfully');
      }, ((error): any => {
        console.error(error);
        console.log(error);
        this.toaster.error(`Error Saving Settings ${error.error.error}`);
      }));
  }
}
