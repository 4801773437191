import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import * as _ from 'lodash';
import { LocalStorageService } from 'angular-2-local-storage';
import { ConfigService } from '../services/config.service'; // do not import from services.module.ts

@Injectable()
export class SurvaleHttpInterceptor implements HttpInterceptor {
  private static authToken: any;
  private readonly authTokenKey: string;
  private unAuthorizedRoutesLevel1 = ['authentication'];

  constructor(private router: Router, private localStorageService: LocalStorageService, private configService: ConfigService) {
    this.authTokenKey = this.configService.localStorageKeys.AuthToken;
    SurvaleHttpInterceptor.authToken = this.localStorageService.get(this.authTokenKey);
  }

  get authToken() {
    return this.localStorageService.get(this.authTokenKey);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.headers.has('Content-Type') && request.url.indexOf('sftp-upload-file') === -1) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    SurvaleHttpInterceptor.authToken = this.authToken;
    // if (!SurvaleHttpInterceptor.authToken) {
    //   console.log('SurvaleHttpInterceptor.authToken', SurvaleHttpInterceptor.authToken);
    // }
    const clonedReq = SurvaleHttpInterceptor.authToken ? request.clone({
      headers: request.headers.set('x-survale-auth-token', SurvaleHttpInterceptor.authToken)
    }) : request;
    const routeToLoginOnErrorAPIs = ['v0/session/validate', 'v0/login-user'];
    return next.handle(clonedReq)
      .pipe(
        catchError((err, caught: Observable<HttpEvent<any>>) => {
          // console.log('Caught error', err);
          if (_.get(err, 'error.msg', '').startsWith('UPDATE PASSWORD:')) {
            //
            this.localStorageService.set('pwdExpiredMsg', err.error);
            this.router.navigate(['authentication', 'change-password']);
            return of(err as any);
          }
          switch (err.status) {
            case 0:
              // bad fix for now
              if (clonedReq.url.indexOf('tag-cloud') === -1 && clonedReq.url.indexOf('client-env') === -1) {
                this.router.navigate(['authentication', 'no-connectivity'], { skipLocationChange: true });
              }
              break;
            case 401:
              const tree: UrlTree = this.router.parseUrl(this.router.url);
              const currentRoute: string =
                tree.root.children[PRIMARY_OUTLET] && tree.root.children[PRIMARY_OUTLET].segments ?
                  tree.root.children[PRIMARY_OUTLET].segments.join('/') : '/';

              if (currentRoute === '/' || (!currentRoute.startsWith(this.unAuthorizedRoutesLevel1[0]))) {
                // this.router.navigate(['authentication', 'login'], {queryParams: {returnUrl: this.router.url}});
                this.router.navigate(['authentication', 'login']);
              }
              return of(err as any);
            case 403:
              /* Login error numbers are set to 400. Process for this error initially
              if (_.get(err, 'error.msg', '').startsWith('UPDATE PASSWORD:')) {
                //
                this.localStorageService.set('pwdExpiredMsg', err.error);
                this.router.navigate(['authentication', 'change-password']);
                return of(err as any);
              }
               */
              break;
            default:
              const isSessionCheckRoute = _.find(routeToLoginOnErrorAPIs, r => clonedReq.url.indexOf(r) > -1);
              if (isSessionCheckRoute) {
                // this.router.navigate(['authentication', 'login'], {queryParams: {returnUrl: this.router.url}});
                this.router.navigate(['authentication', 'login']);
              }
          }
          throw err;
          /*
          if (err instanceof HttpErrorResponse && err.status == 401) {
            const tree: UrlTree = this.router.parseUrl(this.router.url);
            const currentRoute: string =
              tree.root.children[PRIMARY_OUTLET] && tree.root.children[PRIMARY_OUTLET].segments ?
                tree.root.children[PRIMARY_OUTLET].segments.join('/') : '/';

            if (currentRoute === '/' || (!currentRoute.startsWith(this.unAuthorizedRoutesLevel1[0]))) {
              // this.router.navigate(['authentication', 'login'], {queryParams: {returnUrl: this.router.url}});
              this.router.navigate(['authentication', 'login']);
            }
            return of(err as any);
          } else {
            // redirect to login if the API call is for session related stuff and it has failed
            const isSessionCheckRoute = _.find(routeToLoginOnErrorAPIs, r => clonedReq.url.indexOf(r) > -1);
            if (isSessionCheckRoute) {
              // this.router.navigate(['authentication', 'login'], {queryParams: {returnUrl: this.router.url}});
              this.router.navigate(['authentication', 'login']);
            }
          }
          throw err;
          */
        })
      );
  }

  setAuthToken(token) {
    // console.log('setting auth token', token);
    SurvaleHttpInterceptor.authToken = token;
    this.localStorageService.set(this.authTokenKey, SurvaleHttpInterceptor.authToken);
  }
}
