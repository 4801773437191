<mat-card>
  <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
    Search by Email
  </mat-card-title>
  <mat-card-content>
    <div class="row wrap pl-1">

      <div class="select-campaigns" fxFlex="70%">
        <mat-form-field>
          <input matInput placeholder="Email" [(ngModel)]="txtEmail" (change)="getGdrLog($event)">
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-1" *ngIf="emailListInstancesCount > 0 || surveyCount > 0">
      <button mat-button mat-raised-button color="primary" class="ml-1" *ngIf="emailListInstancesCount > 0" (click) = "annonomize()">
        Anonymize User
        <mat-icon>account_circle
        </mat-icon>
      </button>
      <button mat-button mat-raised-button color="warn" class="ml-1" *ngIf="emailListInstancesCount > 0"
              (click)="deleteAllData(false)">
        Delete User but keep Survey Data
        <mat-icon>delete
        </mat-icon>
      </button>
      <button mat-button mat-raised-button color="warn" class="ml-1" (click)="deleteAllData(true)"
              *ngIf="emailListInstancesCount > 0 || surveyCount > 0">
        Delete User & All Related Data
        <mat-icon>delete
        </mat-icon>
      </button>
    </div>
    <div class="row pl-1" *ngIf="txtEmail === lastSearchedFor">
      <h3> {{txtEmail}} has {{surveyCount}} survey(s) </h3>
    </div>
    <ngx-datatable class="material" [rows]="gdpr" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
                   [footerHeight]="50" [rowHeight]="'auto'" [externalPaging]="true" [count]="emailListInstancesCount"
                   [selected]="selected" [selectionType]="'row'">
      <ngx-datatable-column [resizeable]="false" *ngFor="let column of columns; let i = index;" name="{{column.name}}"
                            prop="{{column.prop}}" [flexGrow]="column.flexGrow">
        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          <span *ngIf="column.name == 'Email Address'">
            {{value}}
          </span>
          <span *ngIf="column.name == 'First Name'">
            {{value}}
          </span>
          <span *ngIf="column.name == 'Last Name'">
            {{value}}
          </span>

          <span *ngIf="column.name=='Added'">
            {{value|timeAgo}}
          </span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </mat-card-content>
</mat-card>
