
import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  templateUrl: './sub-menu-item.component.html',
  styleUrls: ['./sub-menu-item.component.scss'],
  selector: 'app-sub-menu-item'
})

export class SubMenuItemComponent {
  @Input() menuItem: any;
  @Input() childItem: any;
  constructor() {
  }

  hasShareId(childItem) {
    return !_.isNil(_.get(childItem, 'data.shareId'));
  }
}
