import { Component } from '@angular/core';
import {
  AppFeatures,
  ConfigService,
  PermissionOptions,
  PermissionTypes,
  SurveyFilterType,
  SurveyQuestionContainerTypes,
  SurveyQuestionTypes,
  UserTypes,
  WidgetFilterKeyType,
  EmployeeCampaignTypes,
} from '../services/config.service';
import { AuthenticationService } from '../services/authentication.service';
import { ClientService } from 'app/services/client.service';
import * as _ from 'lodash';
import { EventTypes } from '../services/google-analytics.service';
import humanizeString from 'humanize-string';
import { Title } from '@angular/platform-browser';
import { DashboardFilter, EventsService, EventsServiceDateRange, SurveyFilter, WidgetDataChangedEventData } from 'app/services/events.service';
import { title } from 'process';
import {
  GridsterItem,
} from 'angular-gridster2';
import { WidgetTitleEditDialogComponent, WidgetTitleEditDialogComponentData } from '@components/survale-widgets/widgets-common/widget-title-dialog/widget-title-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { JsonContentDialogComponent, JsonContentDialogComponentData } from '@components/survale-widgets/widgets-common/json-content-dialog/json-content-dialog.component';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GeneralDataService } from '@services/general-data-service';
import { AbstractControl } from '@angular/forms';
import { MenuItems } from 'app/shared/menu-items/menu-items';
const dataCache = {};

@Component({ template: '' })
export class SurvaleCommonComponent {
  private user;

  constructor(
    public authenticationService: AuthenticationService,
  ) {
    this.user = this.authenticationService.getCurrentUser();
    this.authenticationService.onCurrentUserChanged$
      .subscribe((currentUser: any): void => this.user = currentUser);
  }

  get permissionTypes(): typeof PermissionTypes {
    return PermissionTypes;
  }

  get permissionOptions(): typeof PermissionOptions {
    return PermissionOptions;
  }

  get userTypes(): typeof UserTypes {
    return UserTypes;
  }

  get userTypeKeys(): string[] {
    return Object.keys(UserTypes);
  }

  get isSurvaleAdmin(): boolean {
    return this.user ? this.user.isSurvaleAdmin : false;
  }

  public get appFeatures(): typeof AppFeatures {
    return AppFeatures;
  }

  get currentUser(): any {
    return this.user;
  }

  get hasExternalAuth(): boolean {
    const ap = _.get(this.user, 'authProvider', 'Survale') || 'Survale';
    return (ap !== 'Survale');
  }

  get surveyQuestionContainerTypes(): typeof SurveyQuestionContainerTypes {
    return SurveyQuestionContainerTypes;
  }

  get widgetFilterKeyTypes(): typeof WidgetFilterKeyType {
    return WidgetFilterKeyType;
  }

  get minDrillDownFloorLimit(): number {
    return 0;
  }

  get drillDownLimitMsg(): string {
    return 'Your organization restricts drilling down when responses are less than ';
  }

  get isSandboxAccount(): boolean {
    if (!this.user) {
      return false;
    } else {
      return _.get(this.user, 'clientSettings.clientTypeSettings.clientType') === 'sandbox';
    }
  }

  get isCampaignPaused(): boolean {
    if (!this.user) {
      return false;
    } else {
      return _.get(this.user, 'clientSettings.clientTypeSettings.clientType') === 'campaignPaused';
    }
  }

  get hasMainClientId(): boolean {
    const mcId = _.get(this.currentUser, 'clientSettings.clientTypeSettings.mainClientId');
    if (_.isNil(mcId)) {
      alert('Main client not set for this client');
    }
    return !_.isNil(mcId);
  }

  get skeletonStandardTheme(): object {
    return { width: 'auto', height: '90%', margin: '5%', 'aspect-ratio': '1 / 1' };
  }

  get skeletonStandardThemeSquare(): object {
    return { width: '90%', height: '90%', margin: '1% 5%', 'border-radius': '10px' };
  }

  get employeeCampaignTypes(): typeof EmployeeCampaignTypes {
    return EmployeeCampaignTypes;
  }

  public get eventTypes(): typeof EventTypes {
    return EventTypes;
  }

  static getInMemoryCache(cacheType: string, id: string | number): any {
    return dataCache[`${cacheType}-${id}`];
  }

  static setImMemoryCache(cacheType: string, id: string | number, value: any): void {
    dataCache[`${cacheType}-${id}`] = value;
  }

  public trackGAHoverEvent(eventName: EventTypes): void {
    this.authenticationService.trackGAEvent(eventName, 'cande', 'cande', 'cande', 1);
  }

  public fakeOperationalFieldClick(eventName: EventTypes): void {
    this.trackGAClickEvent(eventName);
  }

  public trackGAClickEvent(eventName: EventTypes): void {
    this.authenticationService.trackGAEvent(eventName, 'cande', 'cande', 'cande', 1);
  }

  /**
   * this is essentially hiding functionality from cande
   * @param featureName
   */
  isFeatureHidden(featureName: string): boolean {
    // TODO: this must be replaced by AppFeatures
    // const hiddenFeatures = ['qbr', 'dashboardFilters', 'compareQuestions', 'filterManualEntry', 'exportComments',
    // 'exportData', 'toggleSurveys', 'toggleIndexes', 'toggleComments', 'viewSurvey', 'shareInsight',
    // 'saveSurveyFilter', 'deleteSurveyFilter'];
    // removed exportData from hidden features
    const hiddenFeatures =
      [
        'qbr', 'dashboardFilters', 'compareQuestions', 'filterManualEntry', 'exportComments',
        'toggleSurveys', 'toggleIndexes', 'viewSurvey', 'shareInsight', 'saveSurveyFilter', 'deleteSurveyFilter'
      ];
    const isCande = Number(this.currentUser.parentClientId) === 19 || this.currentUser.clientId === 19;
    if (isCande) {
      return hiddenFeatures.indexOf(featureName) > -1;
    } else {
      return false;
    }
  }

  isFeatureEnabled(feature: AppFeatures, enabledIfUndefined = true): boolean {
    let f = _.get(this.user, 'featureWhitelist');
    if (_.isNil(f) && enabledIfUndefined) {
      f = ['all'];
    } else if (_.isNil(f)) {
      f = [];
    }
    if (typeof f === 'string') {
      f = JSON.parse(f);
    }
    return f.indexOf('all') > -1 || f.indexOf(feature) > -1;
  }


  isCande(): boolean {
    return Number(this.currentUser.parentClientId) === 19 || this.currentUser.clientId === 19;
  }

  isTmp(): boolean {
    return Number(this.currentUser.parentClientId) === 353 || this.currentUser.clientId === 353;
  }

  isBE(): boolean {
    return this.currentUser.clientType === 'BE_PAID_CUSTOMER';
  }

  isBEAdmin(user: any): boolean {
    return user && user.roleName.toLowerCase() === 'admin' && this.isBE();
  }

  isAppUser(): boolean {
    return this.currentUser.userType === 'appUser';
  }

  isParentClient(): boolean {
    return this.currentUser.parentClientId === -1;
  }

  // needs to be in sync with api-common
  hasPermission(permissionType: PermissionTypes, permission: PermissionOptions = PermissionOptions.View): boolean {

    if (!this.user || _.isNil(this.user.role.permJSON[permissionType])) {
      // if (ConfigService.environment.name === 'dev') {
      //   console.log(`No Permission for ${permissionType} in Role`);
      // }
      return false;
      // if not found and permission i promocode then default to false, else default to true
      // const defaultToFalsePermissions = [
      //   PermissionTypes.PromoCodes, PermissionTypes.Clients, PermissionTypes.ManageRoles,
      //   PermissionTypes.ManageOAuth, PermissionTypes.CanEmailDashboardsSnapshots,
      //   PermissionTypes.CanEmailInsightsSnapshots
      // ];
      // return defaultToFalsePermissions.indexOf(permissionType) === -1;
    }
    return this.user.role.permJSON[permissionType][permission];
  }

  navigateTo(router: any, path: any, queryParams = undefined): void {
    if (router.navigated) {
      router.navigate(path, { queryParams });
    } else {
      setTimeout((): void => this.navigateTo(router, path), 1000);
    }
  }

  getGridsterClean(gridsterItems: any): any[] {
    const girdsterToSave = [];
    _.each(gridsterItems, (gi): any => {
      const o = _.cloneDeep(gi);
      o.data = _.omit(o.data,
        ['benchmarkData', 'surveyResponse', 'surveyResponseCopy', 'compareWithSiblingOptions', 'dragEnabled', 'resizeEnabled']
      );
      girdsterToSave.push(o);
    });
    return girdsterToSave;
  }

  public humanize(text: string): string {
    return humanizeString(text);
  }

  convertFilter(filter: DashboardFilter): SurveyFilter {
    const r: SurveyFilter = new SurveyFilter(
      filter.questionId, filter.key, SurveyQuestionTypes.MultipleChoice,
      SurveyFilterType.TypeN,
      filter.selectedValues, [], [], [])
      ;
    return r;
  }

  createSurveyResultGridsterItem(surveyQuestion: any): GridsterItem {
    const gi: GridsterItem = { cols: 6, rows: 4, x: 0, y: 0 };
    gi.data = { question: surveyQuestion };
    gi.data.title = surveyQuestion.questionDisplayIndex ?
      `${surveyQuestion.questionDisplayIndex}. ${surveyQuestion.title}` : surveyQuestion.title;
    gi.dragEnabled = this.hasPermission(this.permissionTypes.EditWidgets, this.permissionOptions.Edit);
    gi.resizeEnabled = this.hasPermission(this.permissionTypes.EditWidgets, this.permissionOptions.Edit);
    // gi.demoData = surveyQuestion.demoData;
    return gi;
  }

  getOperationalFieldAliases(operationalFields: any, operationalFieldAliases: any): string[] {
    _.each(operationalFieldAliases, (value, key): void => {
      const idx = _.findIndex(operationalFields, (cf: any): boolean => cf.key === key);
      _.set(operationalFields[idx], 'alias', value);
    });
    return operationalFields;
  }

  wait(ms = 3000): Promise<void> {
    return new Promise((resolve): any => setTimeout(resolve, ms));
  }

  isValidUrl(url: string): boolean {

    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(url);

    // try {
    //   new URL(url);
    // } catch (e) {
    //   return false;
    // }
    // return true;
  }

  showEditTitleModalImpl(widgetData: any, dialogRef: any, dialog: any, eventsService: any, itemIndex: number): void {
    const config = ConfigService.getDialogConfig();
    const data: WidgetTitleEditDialogComponentData = { title: widgetData.title };
    config.data = data;
    dialogRef = dialog.open(WidgetTitleEditDialogComponent, config);
    dialogRef.afterClosed().subscribe((result: any): void => {
      if (result) {
        if (widgetData.title !== result) {
          widgetData.title = result;
          eventsService.emitWidgetDataChanged(new WidgetDataChangedEventData(itemIndex, widgetData));
          // this.dashboardOps.saveDashboard();
        }
      }
    });
  }

  showJSONContentModal(dialog: MatDialog, jsonContent: any): void {
    const config = ConfigService.getDialogConfigWide();
    const data: JsonContentDialogComponentData = { jsonContent };
    config.data = data;
    dialog.open(JsonContentDialogComponent, config);
  }

  getValidNameForFile(name: string, defaultName: string): string {
    const v = name.replace('^\\.+', '').replace(/[\\\\/:*?"<>|]/g, '');
    return `${v}-${moment().format('YYYY-MM-DD-HH-mm-ss')}-${defaultName}`;
  }

  async partitionFieldSelectedImpl(
    partitionField: AbstractControl,
    generalDataService: GeneralDataService,
    toaster: ToastrService
  ): Promise<void> {
    // console.log('this.partitionField', this.partitionField.value);
    if (partitionField.value) {
      try {
        // await this.wait(5000);
        const v: any = await firstValueFrom(generalDataService.getCustomFieldValues(partitionField.value.hash));
        return v.body;
      } catch (error) {
        toaster.error('Error loading operational field values');
      }
    }
    // console.log('this.operationalFieldValues', operationalFieldValues);
  }

  returnCSVPart(v: string): string {
    return v.toString().indexOf('"') > -1 ? `"${v}"` : v;
  }

  isOnHome(url: string): boolean {
    return url.indexOf('/home') > -1;
  }

  getRoutePostLogin(menuItems: MenuItems, excludeWelcome: boolean): string[] {
    const viewsMenu = _.find(menuItems.getAll(), (i): boolean => i.state === 'views');
    const dashboardsMenu = _.find(menuItems.getAll(), (i): boolean => i.state === 'dashboards');
    // console.log('viewsMenu', (viewsMenu), _.cloneDeep(viewsMenu.children.length));
    if (viewsMenu.children.length > 0) {
      // @ts-ignore
      const firstDashboardElem = viewsMenu.children[0].children[1].state;
      let secondDashboardElem;
      if (viewsMenu.children[0].children[2]) {
        secondDashboardElem = viewsMenu.children[0].children[2].state;
      }
      // @ts-ignore
      const dashboardId = firstDashboardElem === 'survey-analysis' ?
        (secondDashboardElem === 'sentiment-analysis' ?
          viewsMenu.children[0].children[3].state :
          viewsMenu.children[0].children[2].state) :
        firstDashboardElem;
      const viewId = viewsMenu.children[0].state;
      // console.log('::url', viewId, dashboardId, url);
      const cu = this.authenticationService.getCurrentUser();
      if (this.isBE() && !excludeWelcome) {
        return ['/welcome'];
      }

      if (cu.clientSource === 'CANDE-MAIN') {
        // if (url === '' || this.isOnHome(url)) {
        // console.log('redirecting to surveys', viewId);
        // redirect to dashboard if url is blank
        return ['/', 'views', viewId, 'surveys'];
        // }
      } else {
        // console.log('redirecting to dashboard', viewId, dashboardId, url);
        console.log('redirecting to dashboard: ' + '\n');
        // if (excludeWelcome || url === '' || this.isOnHome(url)) {
        // console.log('redirecting to dashboard 2', viewId, dashboardId);
        // redirect to dashboard if url is blank
        return ['/', 'views', viewId, 'dashboard', dashboardId];
        // }
      }
    } else {
      if (dashboardsMenu.children.length > 0) {
        // dashboardsMenu. = true
        const dashboardId = dashboardsMenu.children[0].state;
        const shareId = _.get(dashboardsMenu.children[0], 'data.shareId');
        console.log('redirecting to dashboard:', dashboardId);
        // if (excludeWelcome || url === '' || this.isOnHome(url)) {
        // redirect to dashboard if url is blank
        if (shareId) {
          return ['/', 'dashboards', dashboardId, shareId];
          // console.log('redirecting to dashboard:', dashboardId, shareId);
        } else {
          return ['/', 'dashboards', dashboardId];
        }
        // }

      } else {
        // insight user
        const insightsMenu = _.find(menuItems.getAll(), (i): boolean => i.state === 'insights');
        // if (insightsMenu.children.length === 0) {
        //   this.initializeCounter = initializeCounter + 1;
        //   if (this.initializeCounter < 15) {
        //     // menus has not loaded
        //     setTimeout((): void => this.initialize(this.initializeCounter), 1000);
        //   } else {
        //     // this.authenticationService.clearCurrentUser();
        //     // const params = {
        //     //   reason: 'no-menus',
        //     //   views: viewsMenu.children.length,
        //     //   sharedInsights: insightsMenu.children.length,
        //     //   sharedDashboards: dashboardsMenu.children.length
        //     // };
        //     // this.navigateTo(this.router, ['/', 'no-access'], params);
        //   }
        //   return;
        // }
        const insightId = insightsMenu.children[0].state;
        console.log('redirecting to insight:', insightId);
        console.log('redirecting to insight : ' + insightId + ' ' + '\n');
        // if (url === '' || this.isOnHome(url)) {
        // redirect to dashboard if url is blank
        return ['/', 'insights', insightId];
        // }
      }
    }
  }

  clickElement(id: string, attempt: number): void {
    const element: HTMLAnchorElement = document.getElementById(id) as HTMLAnchorElement;
    if (element) {
      // console.log('element', element);
      if (!element.ariaExpanded) {
        element.click();
        element.ariaExpanded = 'expanded';
      }
    } else if (attempt < 5) {
      setTimeout((): void => this.clickElement(id, attempt + 1), 50);
    } else {
      console.log('element not found', id);
    }
  }

  isCustomDateRangeSelected(eventsService: EventsService): boolean {
    // return dateRange.startDate && dateRange.endDate;
    // console.log('isCustomRangeSelected', eventsService.getCurrentDateRangeLabel());
    return _.isNil(eventsService.getCurrentDateRangeLabel());
  }
}
