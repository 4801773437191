<div [style.minHeight]="'40vh'">
  <h5>Security</h5>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="49%">
      <h5 class="no-weights">
        Candidate Sensitive Fields
        <small><br />These fields will not show up in any exports or will not be displayed in the UI</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%">
          <mat-chip-list aria-label="Candidate Sensitive Fields" *ngIf="settings && settings.candidateSensitiveFields">
            <mat-chip color="primary" selected *ngFor="let item of settings.candidateSensitiveFields; let i = index">
              {{item.value}} &nbsp;&nbsp;
              <i class="fa fa-trash" (click)="settings.candidateSensitiveFields.splice(i, 1)"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="80%">
          <mat-form-field appearance="outline">
            <mat-label>Widget Fields</mat-label>
            <mat-select [(ngModel)]="cSensitiveField">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button class="mat-green" mat-icon-button matSuffix
              (click)="addField('candidateSensitiveFields', cSensitiveField)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>

        </div>
      </div>
    </div>
    <div fxFlex="49%">
      <h5 class="no-weights">
        Employee Sensitive Fields
        <small><br />These fields will not show up in any exports or will not be displayed in the UI</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%">
          <mat-chip-list aria-label="Candidate Sensitive Fields" *ngIf="settings && settings.employeeSensitiveFields">
            <mat-chip color="primary" selected *ngFor="let item of settings.employeeSensitiveFields; let i = index">
              {{item.value}} &nbsp;&nbsp;<i class="fa fa-trash"
                (click)="settings.employeeSensitiveFields.splice(i, 1)"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="55%">
          <mat-form-field appearance="outline">
            <mat-label>Widget Fields</mat-label>
            <mat-select [(ngModel)]="eSensitiveField">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button class="mat-green" mat-icon-button matSuffixÍ
              (click)="addField('employeeSensitiveFields', eSensitiveField)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="49%">
      <h5 class="no-weights">
        Candidate Sensitive Fields for Exports
        <small><br />These fields will not show up in any exports or will not be displayed in the UI</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%" *ngIf="settings">
          <mat-chip-list aria-label="Candidate Sensitive Fields"
            *ngIf="settings && settings.candidateSensitiveFieldsExports">
            <mat-chip color="primary" selected
              *ngFor="let item of settings.candidateSensitiveFieldsExports; let i = index">
              {{item.value}} &nbsp;&nbsp;
              <i class="fa fa-trash" (click)="settings.candidateSensitiveFieldsExports.splice(i, 1)"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="80%" *ngIf="settings">
          <mat-form-field appearance="outline">
            <mat-label>Widget Fields</mat-label>
            <mat-select [(ngModel)]="cSensitiveFieldExport">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button class="mat-green" mat-icon-button matSuffix
              (click)="addField('candidateSensitiveFieldsExports', cSensitiveFieldExport)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>
          <mat-checkbox class="example-margin" [(ngModel)]="settings.includeCandidateSensitiveFieldsInAutomatedExports">
            Include Candidate Sensitive Fields in Automated Exports
          </mat-checkbox>

        </div>
      </div>
    </div>
    <div fxFlex="49%">
      <h5 class="no-weights">
        Employee Sensitive Fields for Exports
        <small><br />These fields will not show up in any exports or will not be displayed in the UI</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%" *ngIf="settings">
          <mat-chip-list aria-label="Candidate Sensitive Fields"
            *ngIf="settings && settings.employeeSensitiveFieldsExports">
            <mat-chip color="primary" selected
              *ngFor="let item of settings.employeeSensitiveFieldsExports; let i = index">
              {{item.value}} &nbsp;&nbsp;<i class="fa fa-trash"
                (click)="settings.employeeSensitiveFieldsExports.splice(i, 1)"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="55%" *ngIf="settings">
          <mat-form-field appearance="outline">
            <mat-label>Widget Fields</mat-label>
            <mat-select [(ngModel)]="eSensitiveFieldExport">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button mat-icon-button class="mat-green" matSuffix
              (click)="addField('employeeSensitiveFieldsExports', eSensitiveFieldExport)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>
          <mat-checkbox class="example-margin" [(ngModel)]="settings.includeEmployeeSensitiveFieldsInAutomatedExports">
            Include Employee Sensitive Fields in Automated Exports
          </mat-checkbox>

        </div>
      </div>
    </div>
  </div>
  <hr />

  <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="settings">
    <div fxFlex="49%">
      <h5 class="no-weights">
        Widget Blacklist
        <small><br />These fields will not show up in survey Widgets, and in any of the filters</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="100%" *ngIf="settings">
          <mat-chip-list aria-label="Candidate Sensitive Fields" *ngIf="settings && settings.widgetBlackList">
            <mat-chip color="primary" selected *ngFor="let item of settings.widgetBlackList; let i = index">
              {{item.value}} &nbsp;&nbsp;<i class="fa fa-trash" (click)="settings.widgetBlackList.splice(i, 1)"></i>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="80%">
          <mat-form-field appearance="outline">
            <mat-label>Widget Fields</mat-label>
            <mat-select [(ngModel)]="widgetBlackListField">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button class="mat-green" mat-icon-button matSuffix
              (click)="addField('widgetBlackList', widgetBlackListField)">
              <mat-icon>add</mat-icon>

            </button>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxFlex="49%">
      <h5 class="no-weights">
        Survey's and insight drill down floor limit
        <small><br />Users will not be able to drill down lower than this number</small>
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <div fxFlex="30%" *ngIf="settings">
          <mat-form-field appearance="outline">
            <mat-label>Drill Down Floor Limit</mat-label>
            <input matInput type="number" min="3" [(ngModel)]="settings.drillDownFloorLimit" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div>

    <div>
      <h5 class="no-weights">
        Rename Filters
      </h5>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <mat-form-field appearance="outline">
          <mat-label>Filters</mat-label>
          <mat-select [(ngModel)]="currentCustomField" (selectionChange)="showRenameField=true;">
            <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
          </mat-select>
          <!-- <button mat-raised-button class="mat-green" mat-icon-button matSuffix
            (click)="addField('candidateSensitiveFields', cSensitiveField)">
            <mat-icon>add</mat-icon>
          </button> -->
        </mat-form-field>


        <mat-form-field *ngIf="showRenameField" style="margin-left: 25px">
          <mat-label>Rename</mat-label>
          <input matInput placeholder="" [(ngModel)]="renamedField">
        </mat-form-field>

        <p>
          <button class="mat-raised-button mat-green" *ngIf="showRenameField" style="height:30px;width:30px"
            (click)="saveOperationalFieldAlias(currentCustomField,renamedField)">
            <i class="fa fa-check"></i>
          </button>
        </p>


      </div>
      <div fxLayout="row wrap" fxLayoutGap="10px">
        <!-- <span *ngFor="let cf of customFields">
          <span *ngIf="cf.alias">{{cf.value}} - {{cf.alias}}</span>
        </span> -->
        <button mat-raised-button mat-primary (click)="showOpFieldsAliases()">Show Aliases</button>

      </div>
    </div>

  </div>
  <hr />
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="49%">
      <h5 class="no-weights"> Data Retention Rules </h5>
      <div *ngIf="settings && settings.dataRetentionRules && (settings.dataRetentionRules.archiveAfterDays || settings.dataRetentionRules.archiveAfter)">
        <span *ngIf="settings.dataRetentionRules.archiveAfterDays">
          Take Action After: <strong>{{settings.dataRetentionRules.archiveAfterDays}}</strong> days<br />
        </span>
        <span *ngIf="!settings.dataRetentionRules.archiveAfterDays">
          Take Action After: <strong>{{settings.dataRetentionRules.archiveAfter}}</strong> months<br />
        </span>
        Candidates without Surveys:
        <strong>{{getLiValue(settings.dataRetentionRules.actionWithoutSurveys)}}</strong><br />
        Data with Surveys: <strong>{{getLiValue(settings.dataRetentionRules.actionWithSurveys)}}</strong>
      </div>
      <div *ngIf="settings && (!settings.dataRetentionRules || !(settings.dataRetentionRules.archiveAfterDays || settings.dataRetentionRules.archiveAfter))">
        No catchall rules defined.
      </div>
      <button mat-raised-button class="mat-green" matSuffix (click)="showDataRetentionDialog()">Change</button>
      <br />
      <br />
    </div>
  </div>
  <hr />

  <h5 class="no-weights">
    IP Address Whitelist
    <small><br />If Enabled, users from only there IP Addresses can log into the system</small>
  </h5>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="55%">
      <mat-form-field appearance="outline">
        <mat-label>Use IP WhiteList</mat-label>
        <mat-select [(ngModel)]="useIpWhiteList">
          <mat-option [value]="1">Yes</mat-option>
          <mat-option [value]="0">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="70%">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Ip Address. Please Enter multiple IP Addresses. One on each line</mat-label>
        <textarea matInput [(ngModel)]="ipWhiteList" cols="80" rows="5" style="font-family: monospace">

        </textarea>
      </mat-form-field>
      <mat-hint>&nbsp;One on each line followed by <code>//</code> for notes</mat-hint>
    </div>
    <div fxFlex="25%">
      E.g.
      <pre style="width: 100%">201.45.345.0/24 // Eng Intranet
255.34.24.67    // David's HO
201.45.0.0/48   // N. Korea</pre>
    </div>
  </div>

  <hr />

  <h5 class="no-weights">
    API Keys
  </h5>
  <div fxLayout="row wrap" fxLayoutGap="10px">
    <div fxFlex="99%">
      <div *ngIf="!apiUser">Create a API user first to generate API Key</div>
      <div *ngIf="apiUser">
        <div *ngIf="!clientAPIKeys || clientAPIKeys.length === 0">No API Keys</div>
        <ol>
          <li *ngFor="let o of clientAPIKeys">
            <pre>{{o.key}}</pre>
            created {{o.created | amTimeAgo}} <span *ngIf="!o.enabled">(Disabled)</span>
            <button *ngIf="!o.enabled" mat-button (click)="removeAPIKey(o)">Delete</button>
            <button *ngIf="o.enabled" mat-button (click)="disableAPIKey(o)">Disable</button>
            <button *ngIf="!o.enabled" mat-button (click)="enableAPIKey(o)">Enable</button>
          </li>
        </ol>
      </div>
    </div>
    <div fxFlex="99%">
      <button mat-raised-button class="mat-green" (click)="createAPIKey()">
        Generate API Key
      </button>

    </div>
  </div>

  <hr />
  <h5 class="no-weights" *ngIf="isSurvaleAdmin">
    SlackBot Settings
  </h5>
  <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="isSurvaleAdmin && settings">
    <div fxFlex="45%">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Bot User oAuth Token </mat-label>
        <input matInput type="text" [(ngModel)]="settings.slackBotSettings.botUserOAuthToken" />
      </mat-form-field>
    </div>
  </div>

  <hr *ngIf="isSurvaleAdmin" />
  <h5 class="no-weights" *ngIf="isSurvaleAdmin && settings">
    Incoming Web Hook URL ({{ settings.webHookType }})
    <div class="label label-info" *ngIf="webHookStatus?.success === false">Webhook URL not created</div>
    <div class="mat-text-warn" *ngIf="webHookStatus?.status !== 'active'">
      <b>Webhook not Enabled</b>
    </div>
    <div class="mat-text-warn" *ngIf="webHookStatus?.status === 'active'">
      <b>Webhook Enabled</b>
    </div>
    <div class="mat-text-warn " *ngIf="webHookStatus?.triggers?.length > 0">
      Only enabled for "{{webHookStatus?.triggers.join(', ')}}"
    </div>
  </h5>
  <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="isSurvaleAdmin && settings">
    <mat-form-field appearance="outline" style="width: 30%">
      <mat-select [(ngModel)]="settings.webHookType" floatLabel="always">
        <mat-label>Webhook type</mat-label>
        <mat-option value="gh">Greenhouse</mat-option>
        <mat-option value="json">Generic JSON</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="isSurvaleAdmin && settings">
    <div fxFlex="45%" *ngIf="ghWebHook?.success">
      <pre *ngIf="settings.webHookType === 'json'">
Endpoint URL:  {{ apiHost }}/v0/iwh-json/generic/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}
      </pre>
      <pre *ngIf="settings.webHookType === 'gh'">
Candidate has changed Stage:
        Name: survale_gh_status
        Endpoint URL:  {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=status
        Secret Key: FqCi5CGR9z

Application Updated:
        Name: survale_gh_application_updated
        Endpoint URL: {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=application_updated
        Secret Key: FqCi5CGR9z

Candidate or Prospect Rejected:
        Name: survale_gh_rejected
        Endpoint URL: {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=rejected
        Secret Key: FqCi5CGR9z

Candidate has been Hired:
        Name: survale_gh_hired
        Endpoint URL: {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=hired
        Secret Key: FqCi5CGR9z

Candidate has submitted application:
        Name: candidate_application_submitted
        Endpoint URL: {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=candidate_application_submitted
        Secret Key: FqCi5CGR9z

Candidate has merged
        Name: candidate_merged
        Endpoint URL: {{apiHost}}/v0/iwh-json/gh/{{ghWebHook.clientHash}}/{{ghWebHook.secret}}?trigger=candidate_merged
        Secret Key: FqCi5CGR9z
      </pre>


    </div>
    <div fxFlex="45%" *ngIf="ghWebHook?.success === false">
      <button mat-raised-button class="mat-green" (click)="createGreenHouseWebHookURLs()">
        Generate Webhook URLs
      </button>
    </div>
  </div>


</div>
