import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ClientService } from '@services/client.service';
import { UsersService } from '@services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { SurvaleCommonComponent } from '@common/survale-common.component';
import { AuthenticationService } from '@services/authentication.service';

import { AppCache } from '../../../components/survale-common/cache/app.cache';
import { cond } from 'lodash';


export interface sftpUser {
  username: string;
  clientId: number;
  clientName: string;
  homeDirectory: string;
}

@Component({
  selector: 'app-sftp-access',
  templateUrl: './sftp-access.component.html',
  styleUrls: ['./sftp-access.component.scss']
})
export class SftpAccessComponent extends SurvaleCommonComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmDialog') confirmDialog!: TemplateRef<any>;
  client: any;
  selectedFile: File | null = null;
  selectedUser: sftpUser = null;
  selectedFolder: string = null;
  users: any[];
  displayedColumns: string[] = ['Key', 'LastModified', 'Size', 'Owner.DisplayName', 'actions'];
  dataSource;
  folders = ['candidate-data', 'employee-data', 'onboarding-data'];
  private cache = AppCache.getInstance();
  private cacheTypes = AppCache.cacheTypes();

  constructor(
    public authenticationService: AuthenticationService,
    private usersService: UsersService,
    private clientService: ClientService,
    private dialog: MatDialog
  ) {
    super(authenticationService);
  }

  async ngOnInit(): Promise<void> {
    const data = await this.clientService.getClient().toPromise();
    this.client = data.body[0];
    this.users = (await firstValueFrom((this.usersService.getSftpUsers(this.client.clientId)))).body;
    if (this.users.length === 1) {
      this.selectedUser = this.users[0];
    }
    this.listContents();

  }

  async ngAfterViewInit(): Promise<void> {
    this.selectedFolder = this.cache.getLocalCache(this.cacheTypes.LastUsedValues, 'sftp-folder');

    this.listContents();

  }

  async listContents(): Promise<void> {
    if (!this.selectedUser || !this.selectedFolder) {
      return;
    }
    const res = (await firstValueFrom((await this.usersService.getUserSftpBucketContents(
      `${this.selectedUser.username}/${this.selectedFolder}`)))).body;

    this.dataSource = new MatTableDataSource(res.Contents);
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  async selectedFolderChanged(): Promise<void> {
    this.cache.setLocalCache(this.cacheTypes.LastUsedValues, 'sftp-folder', this.selectedFolder);
    await this.listContents();
  }

  async uploadFile(): Promise<void> {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('Key', `${this.selectedUser.username}/${this.selectedFolder}`);
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      console.log('FormData after append:', formData.get('file')); // Should log the File object

      await firstValueFrom(this.usersService.uploadSftpFile(formData, `${this.selectedUser.username}/${this.selectedFolder}`));
      await this.listContents();
    }
  }

  openConfirmDialog(key: string): void {
    const dialogRef = this.dialog.open(this.confirmDialog, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log('User confirmed the action');
        await firstValueFrom(this.usersService.deleteSftpObject({ Key: key }));
        await this.listContents();
      } else {
        console.log('User canceled the action');
      }
    });
  }

}
