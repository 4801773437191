import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ViewsResolver } from './resolvers/views.resolver';
import { InsightsResolver } from './resolvers/insights.resolver';
import { UserDashboardsResolver } from './resolvers/user-dashboards.resolver';
import { SurveyFiltersResolver } from './resolvers/survey-filters.resolver';
import { EmployeeCampaignsResolver } from './resolvers/employee-campaigns-resolver';
import { SelfDashboardsFiltersResolver } from './resolvers/self-dashboards-filters-resolver';
import { SelfDashboardFiltersResolver } from './resolvers/self-dashboard-filters.resolver';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    resolve: {
      views: ViewsResolver,
      insights: InsightsResolver,
      userDashboards: UserDashboardsResolver,
      surveyFilters: SurveyFiltersResolver,
      employeeCampaigns: EmployeeCampaignsResolver,
      selfDashboardsFilters: SelfDashboardsFiltersResolver
    },
    children: [
      // {
      //   path: 'home',
      //   loadChildren: () => import('./x-home/dashboard.module').then(m => m.DashboardModule)
      // },
      {
        path: 'home',
        loadChildren: () => import('./screens/home/home.module')
          .then(m => m.HomeModule)
      },
      {
        path: 'welcome',
        loadChildren: () => import('./screens/business-edition/be-welcome.module')
          .then(m => m.BusinessEditionWelcomeModule)
      },
      {
        path: 'be-settings',
        loadChildren: () => import('./screens/business-edition-settings/be-settings.module')
          .then(m => m.BusinessEditionSettingsModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./screens/admin/admin.module')
          .then(m => m.AdminModule)
      },
      {
        path: 'toolkit',
        loadChildren: () => import('./screens/toolkit/toolkit.module')
          .then(m => m.ToolkitModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./screens/reports/reports.module')
          .then(m => m.ReportsModule)
      },
      {
        path: 'insights',
        loadChildren: () => import('./screens/insights/insights.module')
          .then(m => m.InsightsModule)
      },
      {
        path: 'saved-user-filters/survey-insights',
        loadChildren: () => import('./screens/survey-filters/survey-filters.module')
          .then(m => m.SurveyFiltersModule)
      },
      {
        path: 'views',
        loadChildren: () => import('./screens/views/views.module')
          .then(m => m.ViewsModule)
      },
      {
        // used when dashboards are shared with insight users
        path: 'dashboards',
        loadChildren: () => import('./screens/views/dashboard/dashboard.module')
          .then(m => m.DashboardModule)
      },
      {
        // used for filtered dashboards
        path: 'saved-user-filters/dashboards',
        loadChildren: () => import('./screens/views/dashboard/dashboard.module')
          .then(m => m.DashboardModule)
      },
      {
        path: 'views/:viewId/surveys',
        loadChildren: () => import('./screens/survey-dashboard/survey-dashboard.module')
          .then(m => m.SurveyDashboardModule)
      },
      {
        path: 'views/:viewId/survey-analysis',
        loadChildren: () => import('./screens/survey-analysis/survey-analysis.module')
          .then(m => m.SurveyAnalysisModule)
      },
      {
        path: 'views/:viewId/sentiment-analysis',
        loadChildren: () => import('./screens/sentiment-analysis/sentiment-analysis.module')
          .then(m => m.SentimentAnalysisModule)
      },
      {
        path: 'views/:viewId/sa',
        loadChildren: () => import('./screens/survey-analysis/survey-analysis.module')
          .then(m => m.SurveyAnalysisModule)
      },
      // { dupe
      //   path: 'views/:viewId/survey-analysis',
      //   loadChildren: () => import('./screens/survey-dashboard/survey-dashboard.module')
      //     .then(m => m.SurveyDashboardModule)
      // },
      {
        path: 'employee-campaign-results',
        loadChildren: () => import('./screens/employee-campaign-results/employee-campaign-results.module')
          .then(m => m.EmployeeCampaignResultsModule)
      },
      {
        path: 'cande-fake-menu-1',
        loadChildren: () => import('./screens/survale-enterprise-cande/survale-enterprise-cande.module')
          .then(m => m.SurvaleEnterpriseCandeModule)
      },
      {
        path: 'cande-fake-menu-2',
        loadChildren: () => import('./screens/survale-enterprise-cande/survale-enterprise-cande.module')
          .then(m => m.SurvaleEnterpriseCandeModule)
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'no-access',
        loadChildren: () => import('./screens/no-access/no-access.module')
          .then(m => m.NoAccessModule)
      },
      {
        path: 'landing',
        loadChildren: () => import('./screens/landing/landing.module')
          .then(m => m.LandingModule)
      },
      {
        path: 'authentication',
        loadChildren: () => import('./screens/session/session.module')
          .then(m => m.SessionModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./error/error.module')
          .then(m => m.ErrorModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'session/404'
  }
];
