<div mat-dialog-title class="dialog-header">
  <button class="close-button" (click)="close()">
    X
  </button>
  <h2>Data Retention Rules <small> - {{emailList ? emailList.title : ' for all lists'}}</small></h2>
</div>
<div mat-dialog-content class="dialog-content">
  <form [formGroup]='dataRetentionForm' *ngIf="dataRetentionForm">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <div fxFlex="100%">
        <!-- <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Process Data After</mat-label>
          <mat-select formControlName="archiveAfter">
            <mat-option *ngFor="let l of [3,6,9,12,18,24,36]" [value]="l">
              {{l}}
            </mat-option>
          </mat-select>
          <span matSuffix>Months</span>
          <mat-error *ngIf="archiveAfter.invalid && archiveAfter.errors.required">Required</mat-error>
          <mat-hint>Data Processing will start after {{archiveAfter.value}} Months</mat-hint>
        </mat-form-field> -->

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Process Data After</mat-label>
          <input matInput type="number" min="7" max="1200" placeholder="Process after (in days)" formControlName="archiveAfterDays" />
          <span matSuffix>Days</span>
          <mat-error *ngIf="archiveAfterDays.invalid && archiveAfterDays.errors.required">Required</mat-error>
          <mat-hint>Data Processing will start after {{archiveAfterDays.value}} Days</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Candidates with Surveys</mat-label>
          <mat-select formControlName="actionWithSurveys">
            <mat-option *ngFor="let item of archiveOptionsWithSurveysV" value="{{item.key}}">{{item.value}}</mat-option>

          </mat-select>
          <mat-error *ngIf="actionWithSurveys.invalid && actionWithSurveys.errors.required">Required</mat-error>
          <mat-hint *ngIf="actionWithSurveys.value === 'L-D-S-A'">
            All the operational fields data like Hiring Manager, Recruiter, Division Departments etc will be lost.
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>Candidates without Surveys</mat-label>
          <mat-select formControlName="actionWithoutSurveys">
            <mat-option *ngFor="let i of archiveOptionsV" [value]="i.key">{{i.value}}</mat-option>
          </mat-select>
          <mat-error *ngIf="actionWithoutSurveys.invalid && actionWithoutSurveys.errors.required">Required</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="allLists.length > 1" appearance="outline" floatLabel="always">
          <mat-label>Also Apply the same policy to following Lists</mat-label>
          <mat-select multiple formControlName="applyToLists">
            <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
            <mat-option *ngFor="let l of allLists" [value]="l.id">
              {{l.title}} {{l.listType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="dialog-footer">

  <div class="buttons-wrap">
    <button mat-raised-button color="primary"
            [disabled]="dataRetentionForm.invalid" (click)="updateRetentionPreferences()">
      Save Retention Preferences
    </button>
    <button mat-raised-button (click)="clearRetentionPreferences()">
      Clear Retention Preferences
    </button>
    <button mat-raised-button (click)="close()">
      Close
    </button>
  </div>
</div>
