<!--<span class="title">Client Classification</span>-->
<h5>Client Type</h5>
<form [formGroup]="clientTypeForm" *ngIf="isSurvaleAdmin && clientTypeForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="20%">
      <mat-form-field appearance="outline">
        <mat-label>Client Status</mat-label>
        <mat-select formControlName="clientType">
          <mat-option value="sandbox">Sandbox</mat-option>
          <mat-option value="production">Production</mat-option>
          <mat-option value="campaignPaused">Campaign Paused</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="20%">
      <mat-form-field appearance="outline">
        <mat-label>Main Client</mat-label>
        <mat-select formControlName="mainClientId" [disabled]="disableNonSandboxFields()">
          <mat-option *ngFor="let c of childClients" [value]="c.clientId">{{c.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="75%">
      <mat-form-field appearance="outline">
        <mat-label>Test Email Addresses, separated by comma. If this is not set email's will go to email address on
          record.</mat-label>
        <textarea matInput formControlName="testEmailAddresses" placeholder=""
          [disabled]="disableNonSandboxFields()"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>

<h5>Classification</h5>
<form [formGroup]="clientClassificationForm" *ngIf="clientClassificationForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Primary Industry</mat-label>
        <mat-select formControlName="primaryIndustry">
          <mat-option *ngFor="let item of primaryIndustries" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Company Revenue</mat-label>
        <mat-select formControlName="companyRevenue">
          <mat-option *ngFor="let item of companyRevenue" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global Revenue</mat-label>
        <mat-select formControlName="globalRevenue">
          <mat-option *ngFor="let item of globalRevenue" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Employee Population</mat-label>
        <mat-select formControlName="employeePopulation">
          <mat-option *ngFor="let item of employeePopulation" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global Employee Population</mat-label>
        <mat-select formControlName="globalEmployeePopulation">
          <mat-option *ngFor="let item of globalEmployeePopulation" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>New Hires</mat-label>
        <mat-select formControlName="newHires">
          <mat-option *ngFor="let item of newHires" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Global New Hires</mat-label>
        <mat-select formControlName="globalNewHires">
          <mat-option *ngFor="let item of globalNewHires" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Recruiting Team Size</mat-label>
        <mat-select formControlName="recruitingTeamSize">
          <mat-option *ngFor="let item of recruitingTeamSize" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Recruiting Team Structure</mat-label>
        <mat-select formControlName="recruitingStructure">
          <mat-option *ngFor="let item of recruitingTeamStructure" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Average Job Reqs</mat-label>
        <mat-select formControlName="averageJobReqs">
          <mat-option *ngFor="let item of averageJobReqs" value="{{item.value}}">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<h5>Logos</h5>
<form [formGroup]="clientLogoForm" *ngIf="clientLogoForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Logo</mat-label>
        <input matInput formControlName="companyLogo">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Background Image</mat-label>
        <input matInput formControlName="backgroundImage">
      </mat-form-field>
    </div>
  </div>
</form>
<h5>Social Media Links</h5>
<form [formGroup]="socialMediaForm" *ngIf="socialMediaForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">

    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Glassdoor</mat-label>
        <input matInput formControlName="glassdoor">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Indeed</mat-label>
        <input matInput formControlName="indeed">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>LinkedIn</mat-label>
        <input matInput formControlName="linkedIn">
      </mat-form-field>
    </div>

    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Facebook</mat-label>
        <input matInput formControlName="facebook">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Twitter</mat-label>
        <input matInput formControlName="twitter">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Instagram</mat-label>
        <input matInput formControlName="instagram">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>YouTube</mat-label>
        <input matInput formControlName="youtube">
      </mat-form-field>
    </div>

  </div>
</form>
<h5>Other Settings</h5>
<form [formGroup]="otherSettingsForm" *ngIf="otherSettingsForm">
  <div fxLayout="row wrap" class="margin-top-10" fxLayoutGap="10px">
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Name</mat-label>
        <input matInput formControlName="clientName">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Privacy Policy URL</mat-label>
        <input matInput formControlName="privacyPolicyUrl">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Phone</mat-label>
        <input matInput formControlName="phone">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Email</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Address</mat-label>
        <input matInput formControlName="clientAddress">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client City</mat-label>
        <input matInput formControlName="clientCity">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client State</mat-label>
        <input matInput formControlName="clientState">
      </mat-form-field>
    </div>
    <div fxFlex="32%">
      <mat-form-field appearance="outline">
        <mat-label>Client Zip</mat-label>
        <input matInput formControlName="clientZip">
      </mat-form-field>
    </div>
  </div>
