<mat-card fxFlex="100%">

  <div fxLayout="row wrap">

    <div fxFlex="100%" class="m-gap p-gap text-right">

      <div fxFlex.gt-sm="70%" fxFlex="100%" style="margin-top: 17px">
        <mat-form-field appearance="outline" style="width:98.5%">
          <mat-label> Search</mat-label>
          <input matInput type="text" [(ngModel)]="searchTerm" (keyup)="searchUsers($event.target.value)" />
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="30%" fxFlex="100%" style="margin-top:20px">
        <button mat-raised-button [disabled]="currentPage === 1" *ngIf="displayedUsers.length < users.length"
          (click)="changePage(-1)"> &lt;
        </button>
        <button mat-raised-button (click)="changePage(1)" *ngIf="displayedUsers.length < users.length"> &gt; </button>
        <a mat-raised-button class="mat-primary margin-left-5"
          *ngIf="hasPermission(permissionTypes.Users, permissionOptions.Edit)" routerLink="add">Add New User</a>
      </div>


    </div>


    <div fxFlex.gt-sm="33.33%" fxFlex="100" class="m-gap p-gap" *ngFor="let user of displayedUsers">
      <mat-card>
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <span> {{user.firstName}} {{user.lastName}} <span *ngIf="user.jobTitle">({{user.jobTitle}})</span></span>
          <span fxFlex></span>
          <button mat-icon-button mat-button-sm [mat-menu-trigger-for]="card1" aria-label="Open card menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-card-title>
        <mat-menu #card1="matMenu" x-position="before">
          <button mat-menu-item [routerLink]="['/admin/users', user.userId]"
            *ngIf="hasPermission(permissionTypes.Users, permissionOptions.Edit) && (isSurvaleAdmin || !isBEAdmin(user))">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item (click)="setTwoFactorAuthentication(user)"
            *ngIf="hasPermission(permissionTypes.TwoFactorAuthentication, permissionOptions.Edit)">
            <mat-icon>looks_two</mat-icon>
            Two Factor Authentication
          </button>
          <button mat-menu-item (click)="setPassword(user)" *ngIf="isSurvaleAdmin || hasPermission(permissionTypes.SetPassword, permissionOptions.Edit)">
            <mat-icon>vpn_key</mat-icon>
            Set Password
          </button>
          <button mat-menu-item (click)="deleteUser(user)"
            *ngIf="isSurvaleAdmin || hasPermission(permissionTypes.DeleteUser, permissionOptions.Edit)">
            <mat-icon>vpn_key</mat-icon>
            Delete User
          </button>
        </mat-menu>
        <hr>
        <mat-card-content>
          <div class="w-100 h-300px">
            <div fxLayout="row" fxLayoutGap="5px">
              <div fxFlex="50%">
                <strong class="pro-des">{{user.userType | humanize}}</strong>
                <div matTooltip="Auth Provider">{{user.authProvider}}</div>
              </div>
              <div fxFlex="50%" style="text-align: right">
                <div class="{{user.userStatus}}" matTooltip="User Status">
                  <strong class="pro-des">{{user.userStatus | humanize}}</strong>
                </div>
                <div *ngIf="user.roleName" matTooltip="Role">{{user.roleName}}</div>
                <div *ngIf="!user.roleName" matTooltip="Role" style="color:crimson;">MISSING</div>
              </div>
            </div>

            <div class="profile-avatar">
              <img *ngIf="user.avatar" src="{{user.avatar || 'data:,'}}" width="200" height="200" alt="user">
              <div *ngIf="!user.avatar" class="center" >
                <i *ngIf="!user.avatar" class="fa fa-image fa-5x"></i>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="5px">
              <div fxFlex="100%" style="overflow: hidden" style="text-align: right;">
                <span *ngIf="user.twoFAEnabled === 1">
                  <i class="fa fa-check-double" [matTooltip]="'Two Factor Authentication Enabled'"></i>
                </span>
                <span>
                  <a href="mailto://{{user.emailAlias || user.email}}">
                    <span matTooltip="Logon Name">{{user.email}}</span>
                    <span *ngIf="user.emailAlias && user.emailAlias !== user.email"
                      matTooltip="Email">{{user.emailAlias}}</span>
                  </a>
                </span>
              </div>
            </div>

            <hr />
            <div fxLayout="row" fxLayoutGap="10px" *ngIf="user.history">
              <div>
                <span class="pro-des"><b>Last Logins: </b></span>
              </div>
              <div *ngFor="let history of user.history">
                <div [matTooltip]="history.created | amTimeAgo" style="text-align: end;">
                  <span class="pro-des">{{history.created | date:'shortDate'}} {{history.created |
                    date:'shortTime'}}</span>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </div>
</mat-card>

<!--  <pre>{{users | json}}</pre>-->
