// Core Modules
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

// Application Services
import { ClientService } from 'app/services/client.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { SurvaleCommonComponent } from '../../../common/survale-common.component';
import { AuthenticationService } from '../../../services/authentication.service';

/**
 * Client classification component
 */
@Component({
  selector: 'app-client-classification',
  templateUrl: './client-classification.component.html',
  styleUrls: ['./client-classification.component.scss']
})
export class ClientClassificationComponent extends SurvaleCommonComponent implements OnInit, OnChanges {

  @Input() settings;
  public primaryIndustries: any;
  public companyRevenue: any;
  public globalRevenue: any;
  public employeePopulation: any;
  public globalEmployeePopulation: any;
  public newHires: any;
  public globalNewHires: any;
  public recruitingTeamSize: any;
  public recruitingTeamStructure: any;
  public clientClassificationForm: FormGroup;
  public otherSettingsForm: FormGroup;
  public socialMediaForm: FormGroup;
  public clientLogoForm: FormGroup;
  public clientTypeForm: FormGroup;
  public childClients: any[];
  public averageJobReqs: any;

  /**
   * Life cycle method
   * @param authenticationService: AuthenticationService
   * @param clientService ClientService
   * @param fb FormBuilder
   * @param toaster toaster
   */
  constructor(
    public authenticationService: AuthenticationService,
    private clientService: ClientService,
    private fb: FormBuilder,
    private toaster: ToastrService
  ) {
    super(authenticationService);
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    // Initialize form
    this.initializeForm();

    this.primaryIndustries = this.clientService.getPrimaryIndustries();
    this.companyRevenue = this.clientService.getCompanyRevenue();
    this.globalRevenue = this.clientService.getGlobalRevenue();
    this.employeePopulation = this.clientService.getEmployeePopulation();
    this.globalEmployeePopulation = this.clientService.getGlobalEmployeePopulation();
    this.averageJobReqs = this.clientService.getAverageJobReqs();
    this.newHires = this.clientService.getCompanyNewHires();
    this.globalNewHires = this.clientService.getGlobalNewHires();
    this.recruitingTeamSize = this.clientService.getRecruitingSize();
    this.recruitingTeamStructure = this.clientService.getRecruitingTeamStructure();
    this.setChildClients();
  }

  setChildClients(): void {
    this.clientService.getChildClients().subscribe(data => {
      this.childClients = _.map(
        _.filter(data.body, c => c.clientId !== this.currentUser.clientId &&
          ( _.isNil(c.mainClientId) || c.mainClientId === 0 || (-1 * c.mainClientId) === this.currentUser.clientId)
        ),
        c => _.pick(c, ['clientId', 'name']));
      this.childClients = _.sortBy(this.childClients, c => c.name);
      // console.log('this.childClients', this.childClients, this.currentUser);
    }, (error): any => {
      this.toaster.error('Error loading sub clients');
    });
  }

  /**
   * Initialize Form
   */
  initializeForm(): void {
    this.socialMediaForm = this.fb.group({
      facebook: new FormControl(),
      twitter: new FormControl(),
      linkedIn: new FormControl(),
      instagram: new FormControl(),
      youtube: new FormControl(),
      glassdoor: new FormControl(),
      indeed: new FormControl(),
      // pinterest: new FormControl(),
      // googlePlus: new FormControl(),
      // snapchat: new FormControl(),
      // tiktok: new FormControl(),
      // other: new FormControl(),
    });

    this.clientLogoForm = this.fb.group({
      companyLogo: new FormControl(),
      backgroundImage: new FormControl(),
    });

    this.clientClassificationForm = this.fb.group({
      primaryIndustry: new FormControl(),
      companyRevenue: new FormControl(),
      globalRevenue: new FormControl(),
      employeePopulation: new FormControl(),
      globalEmployeePopulation: new FormControl(),
      newHires: new FormControl(),
      globalNewHires: new FormControl(),
      recruitingTeamSize: new FormControl(),
      recruitingStructure: new FormControl(),
      averageJobReqs: new FormControl(),
    });


    this.otherSettingsForm = this.fb.group({
      privacyPolicyUrl: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      clientName: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      jobTitle: new FormControl(),
      promoCode: new FormControl(),
      clientAddress: new FormControl(),
      clientCity: new FormControl(),
      clientState: new FormControl(),
      clientZip: new FormControl(),
    });

    if (this.isSurvaleAdmin) {
      this.clientTypeForm = this.fb.group({
        clientType: new FormControl(),
        testEmailAddresses: new FormControl(),
        mainClientId: new FormControl(),
      });
      this.clientTypeForm.patchValue({clientType: 'production'});
    }
  }


  disableNonSandboxFields(): boolean {
    return this.clientTypeForm.controls.clientType.value === 'production';
  }

  /**
   * Set form value when received from parent
   */
  ngOnChanges(): void {

    console.log('this.settings', this.settings);

    if (this.clientClassificationForm && this.settings && this.settings.clientClassification) {
      this.clientClassificationForm.patchValue(this.settings.clientClassification);
    }
    if (this.socialMediaForm && this.settings && this.settings.socialMediaUrls) {
      this.socialMediaForm.patchValue(this.settings.socialMediaUrls);
    }
    if (this.clientLogoForm && this.settings && this.settings.logos) {
      this.clientLogoForm.patchValue(this.settings.logos);
    }
    if (this.otherSettingsForm && this.settings && this.settings.otherSettings) {
      this.otherSettingsForm.patchValue(this.settings.otherSettings);
    }
    
    if (this.isSurvaleAdmin) {
      if (this.clientTypeForm && this.settings && this.settings.clientTypeSettings) {
        this.clientTypeForm.patchValue(this.settings.clientTypeSettings);
        this.setChildClients();
      }
    }
  }
}
