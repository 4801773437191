import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
// import { DashboardService, EventsService } from '../../../services/services.module';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { EmailListService } from '../../../services/services.module';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Component({
  templateUrl: './data-retention.component.html',
  styleUrls: ['./data-retention.component.scss'],
})
export class DataRetentionComponent implements OnInit {
  public static readonly archiveOptions = [
    { key: 'L-D', value: 'Delete List Data' },
    { key: 'L-A', value: 'Anonymize List Data' },
    { key: 'NA', value: 'No-Action' }
  ];
  public static readonly archiveOptionsWithSurveys = [
    { key: 'L-D', value: 'Delete Only List Data' },
    { key: 'L-D_S-A', value: 'Delete List Data & Anonymize Survey Data' },
    { key: 'L-A_S-A', value: 'Anonymize List Data & Anonymize Survey Data' },
    { key: 'NA', value: 'No-Action' }
  ];
  @ViewChild('allSelected') allSelected: any;
  public dataRetentionForm: FormGroup;
  public allLists;
  public readonly emailList: any;
  public readonly settingsMode = 'list';
  public readonly existingSetting: any;

  constructor(public dialogRef: MatDialogRef<DataRetentionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private emailListService: EmailListService,
    private toaster: ToastrService,
    private fb: FormBuilder) {

    if (data.emailList) {
      this.emailList = data.emailList;
    }
    if (data.existingSetting) {
      this.existingSetting = data.existingSetting;
    }
    if (data.settingsMode) {
      this.settingsMode = data.settingsMode;
    }
    this.allLists = _.filter(data.allLists, (l): any => l.listType === 'ats-list' && l.id !== this.emailList.id);
  }

  get archiveAfter(): AbstractControl {
    return this.dataRetentionForm.get('archiveAfter');
  }

  get archiveAfterDays(): AbstractControl {
    return this.dataRetentionForm.get('archiveAfter');
  }
  get actionWithSurveys(): AbstractControl {
    return this.dataRetentionForm.get('actionWithSurveys');
  }

  get archiveOptionsWithSurveysV(): any {
    return DataRetentionComponent.archiveOptionsWithSurveys;
  }

  get archiveOptionsV(): any {
    return DataRetentionComponent.archiveOptions;
  }

  get actionWithoutSurveys(): AbstractControl {
    return this.dataRetentionForm.get('actionWithoutSurveys');
  }

  get applyToLists(): AbstractControl {
    return this.dataRetentionForm.get('applyToLists');
  }

  ngOnInit(): void {
    // initialize form
    this.dialogRef.updatePosition({ top: '50px' });
    this.initializeForm();
    if (this.settingsMode === 'list' && this.emailList) {
      this.emailListService.getDataRetentionRules(this.emailList.id)
        .subscribe({
          next: (resp): void => {
            if (resp.body.archiveAfter) {
              resp.body.archiveAfterDays = resp.body.archiveAfter * 30;
            }
            this.dataRetentionForm.patchValue(resp.body);
          },
          error: (error): any => this.toaster.error('Error Loading Settings')
        });
    }
    if (this.existingSetting?.archiveAfter) {
      this.existingSetting.archiveAfterDays = this.existingSetting.archiveAfter * 30;
    }
    this.dataRetentionForm.patchValue(this.existingSetting);
  }

  initializeForm(): void {
    this.dataRetentionForm = this.fb.group({
      archiveAfter: new FormControl(12, [Validators.required]),
      archiveAfterDays: new FormControl(365, [Validators.required]),
      actionWithSurveys: new FormControl(undefined, [Validators.required]),
      actionWithoutSurveys:
        new FormControl(undefined, [Validators.required]),
      applyToLists: new FormControl('', []),
    });
  }

  toggleAllSelection(): void {
    if (this.allSelected.selected) {
      const v = [...this.allLists.map(item => item.id), 0];
      console.log(v);
      this.dataRetentionForm.controls.applyToLists
        .patchValue(v);
    } else {
      this.dataRetentionForm.controls.applyToLists.patchValue([]);
    }
  }

  async clearRetentionPreferences(): Promise<void> {
    try {
      if (confirm('Do you want to clear Retention Preferences? This will delete all the data retention rules')) {
        if (this.settingsMode === 'list') {
          const resp = await firstValueFrom(this.emailListService.clearDataRetentionRules(this.emailList.id));
          this.toaster.success('Cleared Settings');
          this.dialogRef.close(resp.body);
        } else {
          alert('IMPORTANT: Remember to save the client settings');
          this.dialogRef.close({});
        }
      }
    } catch (e) {
      this.toaster.error('Error Clearing Settings');
    }
  }

  async updateRetentionPreferences(): Promise<void> {
    if (this.dataRetentionForm.valid) {
      if (this.settingsMode === 'list') {
        this.emailListService.setDataRetentionRules(this.emailList.id, this.dataRetentionForm.value)
          .subscribe({
            next: (resp): void => {
              this.toaster.success('Saved Settings');
              this.dialogRef.close(resp.body);
            },
            error: (error): any => this.toaster.error('Error Saving Settings')
          });
      } else {
        this.dialogRef.close(this.dataRetentionForm.value);
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
