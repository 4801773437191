<a appAccordionToggle class="relative" mat-ripple [routerLink]="['/', menuItem.state]" *ngIf="menuItem.type === 'link'">
  <span>{{ menuItem.name | translate }}</span>
  <span fxFlex></span>
  <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">
    {{ badge.value }}
  </span>
</a>
<a appAccordionToggle class="relative" mat-ripple id="accordionToggle-{{menuItem.state}}" href="{{menuItem.state}}"
  target="_blank" *ngIf="menuItem.type === 'extLink'">
  <span>{{ menuItem.name | translate }}</span>
</a>
<span *ngIf="menuItem.type === 'sub'">
  <span *ngIf="menuItem.state !== 'cande-fake-menu-1' && menuItem.state !== 'cande-fake-menu-2'">
    <a appAccordionToggle id="accordionToggle-{{menuItem.state}}" class="relative" mat-ripple href="javascript:;">
      <span>{{ menuItem.name | translate }}</span>
      <span fxFlex></span>
      <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuItem.badge">
        {{ badge.value }}
      </span>
      <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
    </a>
  </span>
</span>

<mat-nav-list appAccordion class="sub-menu navigation relative" *ngIf="menuItem.type === 'sub'">
  <mat-list-item appAccordionLink *ngFor="let childItem of menuItem.children" routerLinkActive="open"
    [class.hidden]="!childItem.visible">
    <!-- sub menu item -->
    <app-sub-menu-item [childItem]="childItem" [menuItem]="menuItem"></app-sub-menu-item>
  </mat-list-item>
</mat-nav-list>
