<mat-card>
  <mat-card-header>
    <mat-card-title class="title" *ngIf="!roleId">New Role</mat-card-title>
    <mat-card-title class="title" *ngIf="roleId"> Edit Role </mat-card-title>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <form [formGroup]="roleGroup" *ngIf="roleGroup" fxLayout="column">
      <mat-form-field appearance="outline" floatLabel="always" fxFlex="100%">
        <mat-label>Role Name</mat-label>
        <input matInput placeholder="Role name" formControlName='roleName'>
        <mat-error *ngIf="roleName.invalid && roleName.errors.required">Role Name is required</mat-error>
      </mat-form-field>
      <table fxFlex="100%" mat-table [dataSource]="permissions" class="mat-elevation-z8" [formGroup]="permJSON">
        <!-- Permissions Column -->
        <ng-container matColumnDef="permissions">
          <th mat-header-cell *matHeaderCellDef> Permissions </th>
          <td mat-cell *matCellDef="let element; let i= index"> {{element}}
        </ng-container>

        <!-- Controls Column -->
        <ng-container matColumnDef="controls">
          <th mat-header-cell *matHeaderCellDef> Permissions </th>
          <td mat-cell *matCellDef="let element; let i= index" [formGroupName]="element">
            <mat-checkbox [id]="'edit_'+ i" formControlName="hasEdit"></mat-checkbox> Edit
            <mat-checkbox [id]="'delete'+ i" formControlName="hasView" class="view"></mat-checkbox> View
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="40%">
          <h5 class="no-weights">Hidden Fields</h5>
          <mat-form-field appearance="outline">
            <mat-label>Operational Fields</mat-label>
            <mat-select formControlName="opField">
              <mat-option *ngFor="let cf of customFields" [value]="cf">{{cf.value}}</mat-option>
            </mat-select>
            <button mat-raised-button class="mat-green" mat-icon-button matSuffix [disabled]="!roleGroup.get('opField').value"
              (click)="addField(roleGroup.get('opField').value)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-form-field>
        </div>
      
        <div fxFlex="60%">
          <div class="hidden-fields-list">
            <mat-chip-list aria-label="Hidden Fields" *ngIf="hiddenOperationalFields">
              <mat-chip color="primary" selected *ngFor="let item of hiddenOperationalFields; let i = index">
                {{item.value}} &nbsp;&nbsp;
                <i class="fa fa-trash" (click)="removeField(i)"></i>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </form>
    
  </mat-card-content>
  <mat-card-actions class="text-right">
    <button mat-raised-button class="mat-green" [disabled] ="roleGroup.invalid" type="submit" (click)="saveRole()">Submit</button>
    <button mat-raised-button routerLink='/admin/roles'>Cancel</button>
  </mat-card-actions>
</mat-card>
