export const environment = {
  name: 'staging',
  enableInspectLet: false,
  production: true,
  apiHost: 'https://staging.survale.com',
  hostUri: 'https://staging.survale.com',
  surveyUrl: 'https://staging.survale.com/survey/#',
  cdn: 'https://static-staging.survale.com',
  shortURLHost : 'https://u-staging.survale.com',
  // googleRecaptchaSiteKey: '6LehBFQUAAAAAP1sav-G1xdk4bzqQrXvB1x_Hyck',
  googleRecaptchaSiteKey: '6Lfrp70ZAAAAAH760v-QhOLNCBPbEN1Hc2PVatVW',
  googleRecaptchaSiteKeyV3: '6LcABE0pAAAAADiQN_fIKzb_1oIwSVV5REaIIEw_',
  cloudinary: {
    uploadPreset: 'wi0dmxe6'
  },
  gaCode: undefined,
  productFruitCode: 'RFTfTF1LnZ9ogNz7',
  productFruitCode_pro: '',
  nextJSSurveyUrl: 'https://s-staging.survale.com',
  alternateSurveyDomains: ['https://s.candidate-feedback.org'],
  // clientIdsUsingNextJSSurveyURL:  [-1], // [-1] is all clients
  portalHost: 'https://staging.survale.com/portal/#',
  clientsWithAIEmbeddings: [-1],
};
