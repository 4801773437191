// Core modules
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClientService } from '@services/client.service';
import {MatTableDataSource} from '@angular/material/table';
import * as _ from 'lodash';
import {MatDialog} from '@angular/material/dialog';
import {ConfigService} from '@services/config.service';
import {JsonContentDialogComponent, JsonContentDialogComponentData} from '@components/survale-widgets/widgets-common/json-content-dialog/json-content-dialog.component';
import * as moment from 'moment';

/**
 * Client Index Weights Component
 */
@Component({
  selector: 'app-other-client-settings',
  templateUrl: './other-client-settings.component.html',
  styleUrls: ['./other-client-settings.component.scss']
})
export class OtherClientSettingsComponent implements OnInit {
  @Input() settings: any;
  public otherSettings: any;
  constructor(private fb: FormBuilder,
    private clientService: ClientService,
    public dialog: MatDialog
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.otherSettings = (await this.clientService.getOtherClientSettings().toPromise()).body;
    for (const setting of this.otherSettings.scheduledJobs) {
      try {
        setting.jobSettings = JSON.parse(setting.jobSettings);
        setting.displayedColumnsJobSettings = ['name', 'email'];
        setting.dataSourceJobSettings = new MatTableDataSource(setting.jobSettings.reportToEmails);
      } catch (e) {}
    }
    for (const job of this.otherSettings.sqs_Jobs) {
      try {
        job.settings = JSON.parse(job.settings);
        if (job.jsProvider === 'workday') {
          const fieldMapTable = this.getFieldMapTableData(job);
          job.displayedColumns = ['clientFieldName', 'internalFieldName', 'displayFieldName',
            'fieldType', 'alias'];
          job.dataSource = new MatTableDataSource(fieldMapTable.data);
        }
      } catch (e) {}
      try {
        job.emailListSettings = JSON.parse(job.emailListSettings);
      } catch (e) {}
      job.humanizedInterval = moment.duration(job.settings.jobInterval, 'minutes').humanize();
    }
    this.otherSettings.sqs_Jobs = _.sortBy(this.otherSettings.sqs_Jobs, ['isDeactivated']);
    // console.log('this.otherSettings', this.otherSettings);
  }


  showJSONContentModal(dialog: MatDialog, jsonContent: any): void {
    const config = ConfigService.getDialogConfigWide();
    const data: JsonContentDialogComponentData = {jsonContent};
    config.data = data;
    dialog.open(JsonContentDialogComponent, config);
  }

  getFieldMapTableData(job) {
    const fieldMap = job?.settings?.api?.fieldMap;
    if (_.isNil(fieldMap)) {
      return {data: []};
    }

    const customFiledMapping = JSON.parse(job?.emailListSettings).emailListJSON?.customFieldMapping;
    const rows = [];

    _.keys(fieldMap).forEach((key) => {
      const value = fieldMap[key];

      if (Array.isArray(value) && key === 'customFields') {
        value.forEach((item) => {
          const displayName = _.findKey(customFiledMapping, (val) => val === item.fieldName);
          const row = {
            internalFieldName: item.fieldName,
            clientFieldName: item.clientFieldName,
            displayFieldName: displayName, 
            calculatedFieldName: '',
            fieldType: item.fieldType ? item.fieldType : '',
            defaultValue: item.defaultValue ? item.defaultValue : '',
            dateFormat: item.dateFormat ? item.dateFormat : '',
            alias: _.isNil(this.settings.operationalFieldAliases[item.fieldName]) ? '-' :
              this.settings.operationalFieldAliases[item.fieldName]
          };
          if (item.type && item.type === 'regex-test') {
            row.internalFieldName = '-';
            row.calculatedFieldName = item.fieldName;
          }
          rows.push(row);          
        });
      } else if (typeof value === 'object' && value !== null) {
        _.keys(value).forEach((subKey) => {
          rows.push({
            internalFieldName: `${key}.${subKey}`,
            clientFieldName: value[subKey],
            displayName: '-',
          });
        });
      } else {
        rows.push({
          internalFieldName: key,
          clientFieldName: value,
          displayName: '-',
        });
      }
    });

    return {data: rows};
  }
}
